/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type RemoveTagMutationVariables = Types.Exact<{
    input: Types.TagAssociationInput;
}>;

export type RemoveTagMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'removeTag'>;

export type AddTagMutationVariables = Types.Exact<{
    input: Types.TagAssociationInput;
}>;

export type AddTagMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'addTag'>;

export type RemoveTermMutationVariables = Types.Exact<{
    input: Types.TermAssociationInput;
}>;

export type RemoveTermMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'removeTerm'>;

export type AddTermMutationVariables = Types.Exact<{
    input: Types.TermAssociationInput;
}>;

export type AddTermMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'addTerm'>;

export type AddLinkMutationVariables = Types.Exact<{
    input: Types.AddLinkInput;
}>;

export type AddLinkMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'addLink'>;

export type RemoveLinkMutationVariables = Types.Exact<{
    input: Types.RemoveLinkInput;
}>;

export type RemoveLinkMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'removeLink'>;

export type AddOwnerMutationVariables = Types.Exact<{
    input: Types.AddOwnerInput;
}>;

export type AddOwnerMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'addOwner'>;

export type RemoveOwnerMutationVariables = Types.Exact<{
    input: Types.RemoveOwnerInput;
}>;

export type RemoveOwnerMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'removeOwner'>;

export type UpdateDescriptionMutationVariables = Types.Exact<{
    input: Types.DescriptionUpdateInput;
}>;

export type UpdateDescriptionMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'updateDescription'>;

export type SetDomainMutationVariables = Types.Exact<{
    entityUrn: Types.Scalars['String'];
    domainUrn: Types.Scalars['String'];
}>;

export type SetDomainMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'setDomain'>;

export type UnsetDomainMutationVariables = Types.Exact<{
    entityUrn: Types.Scalars['String'];
}>;

export type UnsetDomainMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'unsetDomain'>;

export type SetTagColorMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
    colorHex: Types.Scalars['String'];
}>;

export type SetTagColorMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'setTagColor'>;

export type UpdateDeprecationMutationVariables = Types.Exact<{
    input: Types.UpdateDeprecationInput;
}>;

export type UpdateDeprecationMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'updateDeprecation'>;

export const RemoveTagDocument = gql`
    mutation removeTag($input: TagAssociationInput!) {
        removeTag(input: $input)
    }
`;
export type RemoveTagMutationFn = Apollo.MutationFunction<RemoveTagMutation, RemoveTagMutationVariables>;

/**
 * __useRemoveTagMutation__
 *
 * To run a mutation, you first call `useRemoveTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagMutation, { data, loading, error }] = useRemoveTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTagMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveTagMutation, RemoveTagMutationVariables>,
) {
    return Apollo.useMutation<RemoveTagMutation, RemoveTagMutationVariables>(RemoveTagDocument, baseOptions);
}
export type RemoveTagMutationHookResult = ReturnType<typeof useRemoveTagMutation>;
export type RemoveTagMutationResult = Apollo.MutationResult<RemoveTagMutation>;
export type RemoveTagMutationOptions = Apollo.BaseMutationOptions<RemoveTagMutation, RemoveTagMutationVariables>;
export const AddTagDocument = gql`
    mutation addTag($input: TagAssociationInput!) {
        addTag(input: $input)
    }
`;
export type AddTagMutationFn = Apollo.MutationFunction<AddTagMutation, AddTagMutationVariables>;

/**
 * __useAddTagMutation__
 *
 * To run a mutation, you first call `useAddTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagMutation, { data, loading, error }] = useAddTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTagMutation(baseOptions?: Apollo.MutationHookOptions<AddTagMutation, AddTagMutationVariables>) {
    return Apollo.useMutation<AddTagMutation, AddTagMutationVariables>(AddTagDocument, baseOptions);
}
export type AddTagMutationHookResult = ReturnType<typeof useAddTagMutation>;
export type AddTagMutationResult = Apollo.MutationResult<AddTagMutation>;
export type AddTagMutationOptions = Apollo.BaseMutationOptions<AddTagMutation, AddTagMutationVariables>;
export const RemoveTermDocument = gql`
    mutation removeTerm($input: TermAssociationInput!) {
        removeTerm(input: $input)
    }
`;
export type RemoveTermMutationFn = Apollo.MutationFunction<RemoveTermMutation, RemoveTermMutationVariables>;

/**
 * __useRemoveTermMutation__
 *
 * To run a mutation, you first call `useRemoveTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTermMutation, { data, loading, error }] = useRemoveTermMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTermMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveTermMutation, RemoveTermMutationVariables>,
) {
    return Apollo.useMutation<RemoveTermMutation, RemoveTermMutationVariables>(RemoveTermDocument, baseOptions);
}
export type RemoveTermMutationHookResult = ReturnType<typeof useRemoveTermMutation>;
export type RemoveTermMutationResult = Apollo.MutationResult<RemoveTermMutation>;
export type RemoveTermMutationOptions = Apollo.BaseMutationOptions<RemoveTermMutation, RemoveTermMutationVariables>;
export const AddTermDocument = gql`
    mutation addTerm($input: TermAssociationInput!) {
        addTerm(input: $input)
    }
`;
export type AddTermMutationFn = Apollo.MutationFunction<AddTermMutation, AddTermMutationVariables>;

/**
 * __useAddTermMutation__
 *
 * To run a mutation, you first call `useAddTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTermMutation, { data, loading, error }] = useAddTermMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTermMutation(
    baseOptions?: Apollo.MutationHookOptions<AddTermMutation, AddTermMutationVariables>,
) {
    return Apollo.useMutation<AddTermMutation, AddTermMutationVariables>(AddTermDocument, baseOptions);
}
export type AddTermMutationHookResult = ReturnType<typeof useAddTermMutation>;
export type AddTermMutationResult = Apollo.MutationResult<AddTermMutation>;
export type AddTermMutationOptions = Apollo.BaseMutationOptions<AddTermMutation, AddTermMutationVariables>;
export const AddLinkDocument = gql`
    mutation addLink($input: AddLinkInput!) {
        addLink(input: $input)
    }
`;
export type AddLinkMutationFn = Apollo.MutationFunction<AddLinkMutation, AddLinkMutationVariables>;

/**
 * __useAddLinkMutation__
 *
 * To run a mutation, you first call `useAddLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLinkMutation, { data, loading, error }] = useAddLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLinkMutation(
    baseOptions?: Apollo.MutationHookOptions<AddLinkMutation, AddLinkMutationVariables>,
) {
    return Apollo.useMutation<AddLinkMutation, AddLinkMutationVariables>(AddLinkDocument, baseOptions);
}
export type AddLinkMutationHookResult = ReturnType<typeof useAddLinkMutation>;
export type AddLinkMutationResult = Apollo.MutationResult<AddLinkMutation>;
export type AddLinkMutationOptions = Apollo.BaseMutationOptions<AddLinkMutation, AddLinkMutationVariables>;
export const RemoveLinkDocument = gql`
    mutation removeLink($input: RemoveLinkInput!) {
        removeLink(input: $input)
    }
`;
export type RemoveLinkMutationFn = Apollo.MutationFunction<RemoveLinkMutation, RemoveLinkMutationVariables>;

/**
 * __useRemoveLinkMutation__
 *
 * To run a mutation, you first call `useRemoveLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLinkMutation, { data, loading, error }] = useRemoveLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveLinkMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveLinkMutation, RemoveLinkMutationVariables>,
) {
    return Apollo.useMutation<RemoveLinkMutation, RemoveLinkMutationVariables>(RemoveLinkDocument, baseOptions);
}
export type RemoveLinkMutationHookResult = ReturnType<typeof useRemoveLinkMutation>;
export type RemoveLinkMutationResult = Apollo.MutationResult<RemoveLinkMutation>;
export type RemoveLinkMutationOptions = Apollo.BaseMutationOptions<RemoveLinkMutation, RemoveLinkMutationVariables>;
export const AddOwnerDocument = gql`
    mutation addOwner($input: AddOwnerInput!) {
        addOwner(input: $input)
    }
`;
export type AddOwnerMutationFn = Apollo.MutationFunction<AddOwnerMutation, AddOwnerMutationVariables>;

/**
 * __useAddOwnerMutation__
 *
 * To run a mutation, you first call `useAddOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOwnerMutation, { data, loading, error }] = useAddOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOwnerMutation(
    baseOptions?: Apollo.MutationHookOptions<AddOwnerMutation, AddOwnerMutationVariables>,
) {
    return Apollo.useMutation<AddOwnerMutation, AddOwnerMutationVariables>(AddOwnerDocument, baseOptions);
}
export type AddOwnerMutationHookResult = ReturnType<typeof useAddOwnerMutation>;
export type AddOwnerMutationResult = Apollo.MutationResult<AddOwnerMutation>;
export type AddOwnerMutationOptions = Apollo.BaseMutationOptions<AddOwnerMutation, AddOwnerMutationVariables>;
export const RemoveOwnerDocument = gql`
    mutation removeOwner($input: RemoveOwnerInput!) {
        removeOwner(input: $input)
    }
`;
export type RemoveOwnerMutationFn = Apollo.MutationFunction<RemoveOwnerMutation, RemoveOwnerMutationVariables>;

/**
 * __useRemoveOwnerMutation__
 *
 * To run a mutation, you first call `useRemoveOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOwnerMutation, { data, loading, error }] = useRemoveOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOwnerMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveOwnerMutation, RemoveOwnerMutationVariables>,
) {
    return Apollo.useMutation<RemoveOwnerMutation, RemoveOwnerMutationVariables>(RemoveOwnerDocument, baseOptions);
}
export type RemoveOwnerMutationHookResult = ReturnType<typeof useRemoveOwnerMutation>;
export type RemoveOwnerMutationResult = Apollo.MutationResult<RemoveOwnerMutation>;
export type RemoveOwnerMutationOptions = Apollo.BaseMutationOptions<RemoveOwnerMutation, RemoveOwnerMutationVariables>;
export const UpdateDescriptionDocument = gql`
    mutation updateDescription($input: DescriptionUpdateInput!) {
        updateDescription(input: $input)
    }
`;
export type UpdateDescriptionMutationFn = Apollo.MutationFunction<
    UpdateDescriptionMutation,
    UpdateDescriptionMutationVariables
>;

/**
 * __useUpdateDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDescriptionMutation, { data, loading, error }] = useUpdateDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDescriptionMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateDescriptionMutation, UpdateDescriptionMutationVariables>,
) {
    return Apollo.useMutation<UpdateDescriptionMutation, UpdateDescriptionMutationVariables>(
        UpdateDescriptionDocument,
        baseOptions,
    );
}
export type UpdateDescriptionMutationHookResult = ReturnType<typeof useUpdateDescriptionMutation>;
export type UpdateDescriptionMutationResult = Apollo.MutationResult<UpdateDescriptionMutation>;
export type UpdateDescriptionMutationOptions = Apollo.BaseMutationOptions<
    UpdateDescriptionMutation,
    UpdateDescriptionMutationVariables
>;
export const SetDomainDocument = gql`
    mutation setDomain($entityUrn: String!, $domainUrn: String!) {
        setDomain(entityUrn: $entityUrn, domainUrn: $domainUrn)
    }
`;
export type SetDomainMutationFn = Apollo.MutationFunction<SetDomainMutation, SetDomainMutationVariables>;

/**
 * __useSetDomainMutation__
 *
 * To run a mutation, you first call `useSetDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDomainMutation, { data, loading, error }] = useSetDomainMutation({
 *   variables: {
 *      entityUrn: // value for 'entityUrn'
 *      domainUrn: // value for 'domainUrn'
 *   },
 * });
 */
export function useSetDomainMutation(
    baseOptions?: Apollo.MutationHookOptions<SetDomainMutation, SetDomainMutationVariables>,
) {
    return Apollo.useMutation<SetDomainMutation, SetDomainMutationVariables>(SetDomainDocument, baseOptions);
}
export type SetDomainMutationHookResult = ReturnType<typeof useSetDomainMutation>;
export type SetDomainMutationResult = Apollo.MutationResult<SetDomainMutation>;
export type SetDomainMutationOptions = Apollo.BaseMutationOptions<SetDomainMutation, SetDomainMutationVariables>;
export const UnsetDomainDocument = gql`
    mutation unsetDomain($entityUrn: String!) {
        unsetDomain(entityUrn: $entityUrn)
    }
`;
export type UnsetDomainMutationFn = Apollo.MutationFunction<UnsetDomainMutation, UnsetDomainMutationVariables>;

/**
 * __useUnsetDomainMutation__
 *
 * To run a mutation, you first call `useUnsetDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetDomainMutation, { data, loading, error }] = useUnsetDomainMutation({
 *   variables: {
 *      entityUrn: // value for 'entityUrn'
 *   },
 * });
 */
export function useUnsetDomainMutation(
    baseOptions?: Apollo.MutationHookOptions<UnsetDomainMutation, UnsetDomainMutationVariables>,
) {
    return Apollo.useMutation<UnsetDomainMutation, UnsetDomainMutationVariables>(UnsetDomainDocument, baseOptions);
}
export type UnsetDomainMutationHookResult = ReturnType<typeof useUnsetDomainMutation>;
export type UnsetDomainMutationResult = Apollo.MutationResult<UnsetDomainMutation>;
export type UnsetDomainMutationOptions = Apollo.BaseMutationOptions<UnsetDomainMutation, UnsetDomainMutationVariables>;
export const SetTagColorDocument = gql`
    mutation setTagColor($urn: String!, $colorHex: String!) {
        setTagColor(urn: $urn, colorHex: $colorHex)
    }
`;
export type SetTagColorMutationFn = Apollo.MutationFunction<SetTagColorMutation, SetTagColorMutationVariables>;

/**
 * __useSetTagColorMutation__
 *
 * To run a mutation, you first call `useSetTagColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTagColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTagColorMutation, { data, loading, error }] = useSetTagColorMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *      colorHex: // value for 'colorHex'
 *   },
 * });
 */
export function useSetTagColorMutation(
    baseOptions?: Apollo.MutationHookOptions<SetTagColorMutation, SetTagColorMutationVariables>,
) {
    return Apollo.useMutation<SetTagColorMutation, SetTagColorMutationVariables>(SetTagColorDocument, baseOptions);
}
export type SetTagColorMutationHookResult = ReturnType<typeof useSetTagColorMutation>;
export type SetTagColorMutationResult = Apollo.MutationResult<SetTagColorMutation>;
export type SetTagColorMutationOptions = Apollo.BaseMutationOptions<SetTagColorMutation, SetTagColorMutationVariables>;
export const UpdateDeprecationDocument = gql`
    mutation updateDeprecation($input: UpdateDeprecationInput!) {
        updateDeprecation(input: $input)
    }
`;
export type UpdateDeprecationMutationFn = Apollo.MutationFunction<
    UpdateDeprecationMutation,
    UpdateDeprecationMutationVariables
>;

/**
 * __useUpdateDeprecationMutation__
 *
 * To run a mutation, you first call `useUpdateDeprecationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeprecationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeprecationMutation, { data, loading, error }] = useUpdateDeprecationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeprecationMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateDeprecationMutation, UpdateDeprecationMutationVariables>,
) {
    return Apollo.useMutation<UpdateDeprecationMutation, UpdateDeprecationMutationVariables>(
        UpdateDeprecationDocument,
        baseOptions,
    );
}
export type UpdateDeprecationMutationHookResult = ReturnType<typeof useUpdateDeprecationMutation>;
export type UpdateDeprecationMutationResult = Apollo.MutationResult<UpdateDeprecationMutation>;
export type UpdateDeprecationMutationOptions = Apollo.BaseMutationOptions<
    UpdateDeprecationMutation,
    UpdateDeprecationMutationVariables
>;
