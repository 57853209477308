/* eslint-disable */
import * as Types from '../types.generated';

import {
    PlatformFieldsFragment,
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
    InstitutionalMemoryFieldsFragment,
    DeprecationFieldsFragment,
    DataPlatformInstanceFieldsFragment,
    EntityContainerFragment,
    ParentContainersFieldsFragment,
    SchemaMetadataFieldsFragment,
    NonRecursiveDataFlowFieldsFragment,
    NonConflictingPlatformFieldsFragment,
    NonRecursiveMlFeatureFragment,
    NonRecursiveMlPrimaryKeyFragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    PlatformFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
    InstitutionalMemoryFieldsFragmentDoc,
    DeprecationFieldsFragmentDoc,
    DataPlatformInstanceFieldsFragmentDoc,
    EntityContainerFragmentDoc,
    ParentContainersFieldsFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    NonConflictingPlatformFieldsFragmentDoc,
    NonRecursiveMlFeatureFragmentDoc,
    NonRecursiveMlPrimaryKeyFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetAutoCompleteResultsQueryVariables = Types.Exact<{
    input: Types.AutoCompleteInput;
}>;

export type GetAutoCompleteResultsQuery = { __typename?: 'Query' } & {
    autoComplete?: Types.Maybe<
        { __typename?: 'AutoCompleteResults' } & Pick<Types.AutoCompleteResults, 'query' | 'suggestions'> & {
                entities: Array<
                    | ({ __typename?: 'Assertion' } & SearchResultFields_Assertion_Fragment)
                    | ({ __typename?: 'Chart' } & SearchResultFields_Chart_Fragment)
                    | ({ __typename?: 'Container' } & SearchResultFields_Container_Fragment)
                    | ({ __typename?: 'CorpGroup' } & SearchResultFields_CorpGroup_Fragment)
                    | ({ __typename?: 'CorpUser' } & SearchResultFields_CorpUser_Fragment)
                    | ({ __typename?: 'Dashboard' } & SearchResultFields_Dashboard_Fragment)
                    | ({ __typename?: 'DataFlow' } & SearchResultFields_DataFlow_Fragment)
                    | ({ __typename?: 'DataJob' } & SearchResultFields_DataJob_Fragment)
                    | ({ __typename?: 'DataPlatform' } & SearchResultFields_DataPlatform_Fragment)
                    | ({ __typename?: 'DataPlatformInstance' } & SearchResultFields_DataPlatformInstance_Fragment)
                    | ({ __typename?: 'DataProcessInstance' } & SearchResultFields_DataProcessInstance_Fragment)
                    | ({ __typename?: 'Dataset' } & SearchResultFields_Dataset_Fragment)
                    | ({ __typename?: 'Domain' } & SearchResultFields_Domain_Fragment)
                    | ({ __typename?: 'GlossaryTerm' } & SearchResultFields_GlossaryTerm_Fragment)
                    | ({ __typename?: 'MLFeature' } & SearchResultFields_MlFeature_Fragment)
                    | ({ __typename?: 'MLFeatureTable' } & SearchResultFields_MlFeatureTable_Fragment)
                    | ({ __typename?: 'MLModel' } & SearchResultFields_MlModel_Fragment)
                    | ({ __typename?: 'MLModelGroup' } & SearchResultFields_MlModelGroup_Fragment)
                    | ({ __typename?: 'MLPrimaryKey' } & SearchResultFields_MlPrimaryKey_Fragment)
                    | ({ __typename?: 'Notebook' } & SearchResultFields_Notebook_Fragment)
                    | ({ __typename?: 'Tag' } & SearchResultFields_Tag_Fragment)
                    | ({ __typename?: 'VersionedDataset' } & SearchResultFields_VersionedDataset_Fragment)
                >;
            }
    >;
};

export type GetAutoCompleteMultipleResultsQueryVariables = Types.Exact<{
    input: Types.AutoCompleteMultipleInput;
}>;

export type GetAutoCompleteMultipleResultsQuery = { __typename?: 'Query' } & {
    autoCompleteForMultiple?: Types.Maybe<
        { __typename?: 'AutoCompleteMultipleResults' } & Pick<Types.AutoCompleteMultipleResults, 'query'> & {
                suggestions: Array<
                    { __typename?: 'AutoCompleteResultForEntity' } & Pick<
                        Types.AutoCompleteResultForEntity,
                        'type' | 'suggestions'
                    > & {
                            entities: Array<
                                | ({ __typename?: 'Assertion' } & SearchResultFields_Assertion_Fragment)
                                | ({ __typename?: 'Chart' } & SearchResultFields_Chart_Fragment)
                                | ({ __typename?: 'Container' } & SearchResultFields_Container_Fragment)
                                | ({ __typename?: 'CorpGroup' } & SearchResultFields_CorpGroup_Fragment)
                                | ({ __typename?: 'CorpUser' } & SearchResultFields_CorpUser_Fragment)
                                | ({ __typename?: 'Dashboard' } & SearchResultFields_Dashboard_Fragment)
                                | ({ __typename?: 'DataFlow' } & SearchResultFields_DataFlow_Fragment)
                                | ({ __typename?: 'DataJob' } & SearchResultFields_DataJob_Fragment)
                                | ({ __typename?: 'DataPlatform' } & SearchResultFields_DataPlatform_Fragment)
                                | ({
                                      __typename?: 'DataPlatformInstance';
                                  } & SearchResultFields_DataPlatformInstance_Fragment)
                                | ({
                                      __typename?: 'DataProcessInstance';
                                  } & SearchResultFields_DataProcessInstance_Fragment)
                                | ({ __typename?: 'Dataset' } & SearchResultFields_Dataset_Fragment)
                                | ({ __typename?: 'Domain' } & SearchResultFields_Domain_Fragment)
                                | ({ __typename?: 'GlossaryTerm' } & SearchResultFields_GlossaryTerm_Fragment)
                                | ({ __typename?: 'MLFeature' } & SearchResultFields_MlFeature_Fragment)
                                | ({ __typename?: 'MLFeatureTable' } & SearchResultFields_MlFeatureTable_Fragment)
                                | ({ __typename?: 'MLModel' } & SearchResultFields_MlModel_Fragment)
                                | ({ __typename?: 'MLModelGroup' } & SearchResultFields_MlModelGroup_Fragment)
                                | ({ __typename?: 'MLPrimaryKey' } & SearchResultFields_MlPrimaryKey_Fragment)
                                | ({ __typename?: 'Notebook' } & SearchResultFields_Notebook_Fragment)
                                | ({ __typename?: 'Tag' } & SearchResultFields_Tag_Fragment)
                                | ({ __typename?: 'VersionedDataset' } & SearchResultFields_VersionedDataset_Fragment)
                            >;
                        }
                >;
            }
    >;
};

export type SearchResultFields_Assertion_Fragment = { __typename?: 'Assertion' } & Pick<
    Types.Assertion,
    'urn' | 'type'
>;

export type SearchResultFields_Chart_Fragment = { __typename?: 'Chart' } & Pick<
    Types.Chart,
    'urn' | 'type' | 'tool' | 'chartId'
> & {
        properties?: Types.Maybe<
            { __typename?: 'ChartProperties' } & Pick<
                Types.ChartProperties,
                'name' | 'description' | 'externalUrl' | 'type' | 'access'
            > & { lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'> }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        editableProperties?: Types.Maybe<
            { __typename?: 'ChartEditableProperties' } & Pick<Types.ChartEditableProperties, 'description'>
        >;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        dataPlatformInstance?: Types.Maybe<
            { __typename?: 'DataPlatformInstance' } & DataPlatformInstanceFieldsFragment
        >;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
        container?: Types.Maybe<{ __typename?: 'Container' } & EntityContainerFragment>;
        deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
        parentContainers?: Types.Maybe<{ __typename?: 'ParentContainersResult' } & ParentContainersFieldsFragment>;
    };

export type SearchResultFields_Container_Fragment = { __typename?: 'Container' } & Pick<
    Types.Container,
    'urn' | 'type'
> & {
        properties?: Types.Maybe<
            { __typename?: 'ContainerProperties' } & Pick<Types.ContainerProperties, 'name' | 'description'>
        >;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        dataPlatformInstance?: Types.Maybe<
            { __typename?: 'DataPlatformInstance' } & DataPlatformInstanceFieldsFragment
        >;
        editableProperties?: Types.Maybe<
            { __typename?: 'ContainerEditableProperties' } & Pick<Types.ContainerEditableProperties, 'description'>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        tags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        institutionalMemory?: Types.Maybe<{ __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        subTypes?: Types.Maybe<{ __typename?: 'SubTypes' } & Pick<Types.SubTypes, 'typeNames'>>;
        entities?: Types.Maybe<{ __typename?: 'SearchResults' } & Pick<Types.SearchResults, 'total'>>;
        container?: Types.Maybe<{ __typename?: 'Container' } & EntityContainerFragment>;
        deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
        parentContainers?: Types.Maybe<{ __typename?: 'ParentContainersResult' } & ParentContainersFieldsFragment>;
    };

export type SearchResultFields_CorpGroup_Fragment = { __typename?: 'CorpGroup' } & Pick<
    Types.CorpGroup,
    'name' | 'urn' | 'type'
> & {
        info?: Types.Maybe<{ __typename?: 'CorpGroupInfo' } & Pick<Types.CorpGroupInfo, 'displayName' | 'description'>>;
        memberCount?: Types.Maybe<
            { __typename?: 'EntityRelationshipsResult' } & Pick<Types.EntityRelationshipsResult, 'total'>
        >;
    };

export type SearchResultFields_CorpUser_Fragment = { __typename?: 'CorpUser' } & Pick<
    Types.CorpUser,
    'username' | 'urn' | 'type'
> & {
        properties?: Types.Maybe<
            { __typename?: 'CorpUserProperties' } & Pick<
                Types.CorpUserProperties,
                'active' | 'displayName' | 'title' | 'firstName' | 'lastName' | 'fullName' | 'email'
            >
        >;
        info?: Types.Maybe<
            { __typename?: 'CorpUserInfo' } & Pick<
                Types.CorpUserInfo,
                'active' | 'displayName' | 'title' | 'firstName' | 'lastName' | 'fullName' | 'email'
            >
        >;
        editableProperties?: Types.Maybe<
            { __typename?: 'CorpUserEditableProperties' } & Pick<
                Types.CorpUserEditableProperties,
                'displayName' | 'title' | 'pictureLink'
            >
        >;
    };

export type SearchResultFields_Dashboard_Fragment = { __typename?: 'Dashboard' } & Pick<
    Types.Dashboard,
    'urn' | 'type' | 'tool' | 'dashboardId'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DashboardProperties' } & Pick<
                Types.DashboardProperties,
                'name' | 'description' | 'externalUrl' | 'access'
            > & { lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'> }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        editableProperties?: Types.Maybe<
            { __typename?: 'DashboardEditableProperties' } & Pick<Types.DashboardEditableProperties, 'description'>
        >;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        dataPlatformInstance?: Types.Maybe<
            { __typename?: 'DataPlatformInstance' } & DataPlatformInstanceFieldsFragment
        >;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
        container?: Types.Maybe<{ __typename?: 'Container' } & EntityContainerFragment>;
        deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
        parentContainers?: Types.Maybe<{ __typename?: 'ParentContainersResult' } & ParentContainersFieldsFragment>;
    };

export type SearchResultFields_DataFlow_Fragment = { __typename?: 'DataFlow' } & Pick<
    Types.DataFlow,
    'urn' | 'type' | 'orchestrator' | 'flowId' | 'cluster'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DataFlowProperties' } & Pick<Types.DataFlowProperties, 'name' | 'description' | 'project'>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        editableProperties?: Types.Maybe<
            { __typename?: 'DataFlowEditableProperties' } & Pick<Types.DataFlowEditableProperties, 'description'>
        >;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        dataPlatformInstance?: Types.Maybe<
            { __typename?: 'DataPlatformInstance' } & DataPlatformInstanceFieldsFragment
        >;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
        deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
    };

export type SearchResultFields_DataJob_Fragment = { __typename?: 'DataJob' } & Pick<
    Types.DataJob,
    'urn' | 'type' | 'jobId'
> & {
        dataFlow?: Types.Maybe<{ __typename?: 'DataFlow' } & NonRecursiveDataFlowFieldsFragment>;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        properties?: Types.Maybe<
            { __typename?: 'DataJobProperties' } & Pick<Types.DataJobProperties, 'name' | 'description'>
        >;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        editableProperties?: Types.Maybe<
            { __typename?: 'DataJobEditableProperties' } & Pick<Types.DataJobEditableProperties, 'description'>
        >;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
        deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
        dataPlatformInstance?: Types.Maybe<
            { __typename?: 'DataPlatformInstance' } & DataPlatformInstanceFieldsFragment
        >;
    };

export type SearchResultFields_DataPlatform_Fragment = { __typename?: 'DataPlatform' } & Pick<
    Types.DataPlatform,
    'urn' | 'type'
>;

export type SearchResultFields_DataPlatformInstance_Fragment = { __typename?: 'DataPlatformInstance' } & Pick<
    Types.DataPlatformInstance,
    'urn' | 'type'
>;

export type SearchResultFields_DataProcessInstance_Fragment = { __typename?: 'DataProcessInstance' } & Pick<
    Types.DataProcessInstance,
    'urn' | 'type'
>;

export type SearchResultFields_Dataset_Fragment = { __typename?: 'Dataset' } & Pick<
    Types.Dataset,
    'name' | 'origin' | 'uri' | 'platformNativeType' | 'urn' | 'type'
> & {
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        dataPlatformInstance?: Types.Maybe<
            { __typename?: 'DataPlatformInstance' } & DataPlatformInstanceFieldsFragment
        >;
        editableProperties?: Types.Maybe<
            { __typename?: 'DatasetEditableProperties' } & Pick<Types.DatasetEditableProperties, 'description'>
        >;
        properties?: Types.Maybe<
            { __typename?: 'DatasetProperties' } & Pick<
                Types.DatasetProperties,
                'name' | 'description' | 'qualifiedName'
            > & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        subTypes?: Types.Maybe<{ __typename?: 'SubTypes' } & Pick<Types.SubTypes, 'typeNames'>>;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
        container?: Types.Maybe<{ __typename?: 'Container' } & EntityContainerFragment>;
        parentContainers?: Types.Maybe<{ __typename?: 'ParentContainersResult' } & ParentContainersFieldsFragment>;
        deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
    };

export type SearchResultFields_Domain_Fragment = { __typename?: 'Domain' } & Pick<Types.Domain, 'urn' | 'type'> & {
        properties?: Types.Maybe<
            { __typename?: 'DomainProperties' } & Pick<Types.DomainProperties, 'name' | 'description'>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
    };

export type SearchResultFields_GlossaryTerm_Fragment = { __typename?: 'GlossaryTerm' } & Pick<
    Types.GlossaryTerm,
    'name' | 'hierarchicalName' | 'urn' | 'type'
> & {
        properties?: Types.Maybe<
            { __typename?: 'GlossaryTermProperties' } & Pick<
                Types.GlossaryTermProperties,
                'name' | 'description' | 'termSource' | 'sourceRef' | 'sourceUrl' | 'rawSchema'
            > & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
    };

export type SearchResultFields_MlFeature_Fragment = { __typename?: 'MLFeature' } & Pick<
    Types.MlFeature,
    'urn' | 'type'
> &
    NonRecursiveMlFeatureFragment;

export type SearchResultFields_MlFeatureTable_Fragment = { __typename?: 'MLFeatureTable' } & Pick<
    Types.MlFeatureTable,
    'urn' | 'type' | 'name' | 'description'
> & {
        featureTableProperties?: Types.Maybe<
            { __typename?: 'MLFeatureTableProperties' } & Pick<Types.MlFeatureTableProperties, 'description'> & {
                    mlFeatures?: Types.Maybe<
                        Array<Types.Maybe<{ __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn'>>>
                    >;
                    mlPrimaryKeys?: Types.Maybe<
                        Array<Types.Maybe<{ __typename?: 'MLPrimaryKey' } & Pick<Types.MlPrimaryKey, 'urn'>>>
                    >;
                }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
        dataPlatformInstance?: Types.Maybe<
            { __typename?: 'DataPlatformInstance' } & DataPlatformInstanceFieldsFragment
        >;
    };

export type SearchResultFields_MlModel_Fragment = { __typename?: 'MLModel' } & Pick<
    Types.MlModel,
    'name' | 'description' | 'origin' | 'urn' | 'type'
> & {
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
        dataPlatformInstance?: Types.Maybe<
            { __typename?: 'DataPlatformInstance' } & DataPlatformInstanceFieldsFragment
        >;
    };

export type SearchResultFields_MlModelGroup_Fragment = { __typename?: 'MLModelGroup' } & Pick<
    Types.MlModelGroup,
    'name' | 'origin' | 'description' | 'urn' | 'type'
> & {
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
        dataPlatformInstance?: Types.Maybe<
            { __typename?: 'DataPlatformInstance' } & DataPlatformInstanceFieldsFragment
        >;
    };

export type SearchResultFields_MlPrimaryKey_Fragment = { __typename?: 'MLPrimaryKey' } & Pick<
    Types.MlPrimaryKey,
    'urn' | 'type'
> &
    NonRecursiveMlPrimaryKeyFragment;

export type SearchResultFields_Notebook_Fragment = { __typename?: 'Notebook' } & Pick<Types.Notebook, 'urn' | 'type'>;

export type SearchResultFields_Tag_Fragment = { __typename?: 'Tag' } & Pick<
    Types.Tag,
    'name' | 'description' | 'urn' | 'type'
>;

export type SearchResultFields_VersionedDataset_Fragment = { __typename?: 'VersionedDataset' } & Pick<
    Types.VersionedDataset,
    'urn' | 'type'
>;

export type SearchResultFieldsFragment =
    | SearchResultFields_Assertion_Fragment
    | SearchResultFields_Chart_Fragment
    | SearchResultFields_Container_Fragment
    | SearchResultFields_CorpGroup_Fragment
    | SearchResultFields_CorpUser_Fragment
    | SearchResultFields_Dashboard_Fragment
    | SearchResultFields_DataFlow_Fragment
    | SearchResultFields_DataJob_Fragment
    | SearchResultFields_DataPlatform_Fragment
    | SearchResultFields_DataPlatformInstance_Fragment
    | SearchResultFields_DataProcessInstance_Fragment
    | SearchResultFields_Dataset_Fragment
    | SearchResultFields_Domain_Fragment
    | SearchResultFields_GlossaryTerm_Fragment
    | SearchResultFields_MlFeature_Fragment
    | SearchResultFields_MlFeatureTable_Fragment
    | SearchResultFields_MlModel_Fragment
    | SearchResultFields_MlModelGroup_Fragment
    | SearchResultFields_MlPrimaryKey_Fragment
    | SearchResultFields_Notebook_Fragment
    | SearchResultFields_Tag_Fragment
    | SearchResultFields_VersionedDataset_Fragment;

export type FacetFieldsFragment = { __typename?: 'FacetMetadata' } & Pick<
    Types.FacetMetadata,
    'field' | 'displayName'
> & {
        aggregations: Array<
            { __typename?: 'AggregationMetadata' } & Pick<Types.AggregationMetadata, 'value' | 'count'> & {
                    entity?: Types.Maybe<
                        | ({ __typename?: 'Assertion' } & Pick<Types.Assertion, 'urn' | 'type'>)
                        | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                        | ({ __typename?: 'Container' } & Pick<Types.Container, 'urn' | 'type'> & {
                                  platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
                                  properties?: Types.Maybe<
                                      { __typename?: 'ContainerProperties' } & Pick<Types.ContainerProperties, 'name'>
                                  >;
                              })
                        | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'name' | 'type'> & {
                                  properties?: Types.Maybe<
                                      { __typename?: 'CorpGroupProperties' } & Pick<
                                          Types.CorpGroupProperties,
                                          'displayName'
                                      >
                                  >;
                              })
                        | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'username' | 'type'> & {
                                  properties?: Types.Maybe<
                                      { __typename?: 'CorpUserProperties' } & Pick<
                                          Types.CorpUserProperties,
                                          'displayName' | 'fullName'
                                      >
                                  >;
                                  editableProperties?: Types.Maybe<
                                      { __typename?: 'CorpUserEditableProperties' } & Pick<
                                          Types.CorpUserEditableProperties,
                                          'displayName' | 'pictureLink'
                                      >
                                  >;
                              })
                        | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                        | ({ __typename?: 'DataFlow' } & Pick<Types.DataFlow, 'urn' | 'type'>)
                        | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                        | ({ __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'urn' | 'type'> &
                              PlatformFieldsFragment)
                        | ({ __typename?: 'DataPlatformInstance' } & Pick<Types.DataPlatformInstance, 'urn' | 'type'> &
                              DataPlatformInstanceFieldsFragment)
                        | ({ __typename?: 'DataProcessInstance' } & Pick<Types.DataProcessInstance, 'urn' | 'type'>)
                        | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                        | ({ __typename?: 'Domain' } & Pick<Types.Domain, 'urn' | 'type'> & {
                                  properties?: Types.Maybe<
                                      { __typename?: 'DomainProperties' } & Pick<Types.DomainProperties, 'name'>
                                  >;
                              })
                        | ({ __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'urn' | 'type'> & {
                                  properties?: Types.Maybe<
                                      { __typename?: 'GlossaryTermProperties' } & Pick<
                                          Types.GlossaryTermProperties,
                                          'name'
                                      >
                                  >;
                              })
                        | ({ __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn' | 'type'>)
                        | ({ __typename?: 'MLFeatureTable' } & Pick<Types.MlFeatureTable, 'urn' | 'type'>)
                        | ({ __typename?: 'MLModel' } & Pick<Types.MlModel, 'urn' | 'type'>)
                        | ({ __typename?: 'MLModelGroup' } & Pick<Types.MlModelGroup, 'urn' | 'type'>)
                        | ({ __typename?: 'MLPrimaryKey' } & Pick<Types.MlPrimaryKey, 'urn' | 'type'>)
                        | ({ __typename?: 'Notebook' } & Pick<Types.Notebook, 'urn' | 'type'>)
                        | ({ __typename?: 'Tag' } & Pick<Types.Tag, 'name' | 'description' | 'urn' | 'type'> & {
                                  properties?: Types.Maybe<
                                      { __typename?: 'TagProperties' } & Pick<Types.TagProperties, 'colorHex'>
                                  >;
                              })
                        | ({ __typename?: 'VersionedDataset' } & Pick<Types.VersionedDataset, 'urn' | 'type'>)
                    >;
                }
        >;
    };

export type SearchResultsFragment = { __typename?: 'SearchResults' } & Pick<
    Types.SearchResults,
    'start' | 'count' | 'total'
> & {
        searchResults: Array<
            { __typename?: 'SearchResult' } & {
                entity:
                    | ({ __typename?: 'Assertion' } & SearchResultFields_Assertion_Fragment)
                    | ({ __typename?: 'Chart' } & SearchResultFields_Chart_Fragment)
                    | ({ __typename?: 'Container' } & SearchResultFields_Container_Fragment)
                    | ({ __typename?: 'CorpGroup' } & SearchResultFields_CorpGroup_Fragment)
                    | ({ __typename?: 'CorpUser' } & SearchResultFields_CorpUser_Fragment)
                    | ({ __typename?: 'Dashboard' } & SearchResultFields_Dashboard_Fragment)
                    | ({ __typename?: 'DataFlow' } & SearchResultFields_DataFlow_Fragment)
                    | ({ __typename?: 'DataJob' } & SearchResultFields_DataJob_Fragment)
                    | ({ __typename?: 'DataPlatform' } & SearchResultFields_DataPlatform_Fragment)
                    | ({ __typename?: 'DataPlatformInstance' } & SearchResultFields_DataPlatformInstance_Fragment)
                    | ({ __typename?: 'DataProcessInstance' } & SearchResultFields_DataProcessInstance_Fragment)
                    | ({ __typename?: 'Dataset' } & SearchResultFields_Dataset_Fragment)
                    | ({ __typename?: 'Domain' } & SearchResultFields_Domain_Fragment)
                    | ({ __typename?: 'GlossaryTerm' } & SearchResultFields_GlossaryTerm_Fragment)
                    | ({ __typename?: 'MLFeature' } & SearchResultFields_MlFeature_Fragment)
                    | ({ __typename?: 'MLFeatureTable' } & SearchResultFields_MlFeatureTable_Fragment)
                    | ({ __typename?: 'MLModel' } & SearchResultFields_MlModel_Fragment)
                    | ({ __typename?: 'MLModelGroup' } & SearchResultFields_MlModelGroup_Fragment)
                    | ({ __typename?: 'MLPrimaryKey' } & SearchResultFields_MlPrimaryKey_Fragment)
                    | ({ __typename?: 'Notebook' } & SearchResultFields_Notebook_Fragment)
                    | ({ __typename?: 'Tag' } & SearchResultFields_Tag_Fragment)
                    | ({ __typename?: 'VersionedDataset' } & SearchResultFields_VersionedDataset_Fragment);
                matchedFields: Array<{ __typename?: 'MatchedField' } & Pick<Types.MatchedField, 'name' | 'value'>>;
                insights?: Types.Maybe<
                    Array<{ __typename?: 'SearchInsight' } & Pick<Types.SearchInsight, 'text' | 'icon'>>
                >;
            }
        >;
        facets?: Types.Maybe<Array<{ __typename?: 'FacetMetadata' } & FacetFieldsFragment>>;
    };

export type SearchAcrossRelationshipResultsFragment = { __typename?: 'SearchAcrossLineageResults' } & Pick<
    Types.SearchAcrossLineageResults,
    'start' | 'count' | 'total'
> & {
        searchResults: Array<
            { __typename?: 'SearchAcrossLineageResult' } & Pick<Types.SearchAcrossLineageResult, 'degree'> & {
                    entity:
                        | ({ __typename?: 'Assertion' } & SearchResultFields_Assertion_Fragment)
                        | ({ __typename?: 'Chart' } & SearchResultFields_Chart_Fragment)
                        | ({ __typename?: 'Container' } & SearchResultFields_Container_Fragment)
                        | ({ __typename?: 'CorpGroup' } & SearchResultFields_CorpGroup_Fragment)
                        | ({ __typename?: 'CorpUser' } & SearchResultFields_CorpUser_Fragment)
                        | ({ __typename?: 'Dashboard' } & SearchResultFields_Dashboard_Fragment)
                        | ({ __typename?: 'DataFlow' } & SearchResultFields_DataFlow_Fragment)
                        | ({ __typename?: 'DataJob' } & SearchResultFields_DataJob_Fragment)
                        | ({ __typename?: 'DataPlatform' } & SearchResultFields_DataPlatform_Fragment)
                        | ({ __typename?: 'DataPlatformInstance' } & SearchResultFields_DataPlatformInstance_Fragment)
                        | ({ __typename?: 'DataProcessInstance' } & SearchResultFields_DataProcessInstance_Fragment)
                        | ({ __typename?: 'Dataset' } & SearchResultFields_Dataset_Fragment)
                        | ({ __typename?: 'Domain' } & SearchResultFields_Domain_Fragment)
                        | ({ __typename?: 'GlossaryTerm' } & SearchResultFields_GlossaryTerm_Fragment)
                        | ({ __typename?: 'MLFeature' } & SearchResultFields_MlFeature_Fragment)
                        | ({ __typename?: 'MLFeatureTable' } & SearchResultFields_MlFeatureTable_Fragment)
                        | ({ __typename?: 'MLModel' } & SearchResultFields_MlModel_Fragment)
                        | ({ __typename?: 'MLModelGroup' } & SearchResultFields_MlModelGroup_Fragment)
                        | ({ __typename?: 'MLPrimaryKey' } & SearchResultFields_MlPrimaryKey_Fragment)
                        | ({ __typename?: 'Notebook' } & SearchResultFields_Notebook_Fragment)
                        | ({ __typename?: 'Tag' } & SearchResultFields_Tag_Fragment)
                        | ({ __typename?: 'VersionedDataset' } & SearchResultFields_VersionedDataset_Fragment);
                    matchedFields: Array<{ __typename?: 'MatchedField' } & Pick<Types.MatchedField, 'name' | 'value'>>;
                    insights?: Types.Maybe<
                        Array<{ __typename?: 'SearchInsight' } & Pick<Types.SearchInsight, 'text' | 'icon'>>
                    >;
                }
        >;
        facets?: Types.Maybe<Array<{ __typename?: 'FacetMetadata' } & FacetFieldsFragment>>;
    };

export type GetSearchResultsQueryVariables = Types.Exact<{
    input: Types.SearchInput;
}>;

export type GetSearchResultsQuery = { __typename?: 'Query' } & {
    search?: Types.Maybe<{ __typename?: 'SearchResults' } & SearchResultsFragment>;
};

export type GetSearchResultsForMultipleQueryVariables = Types.Exact<{
    input: Types.SearchAcrossEntitiesInput;
}>;

export type GetSearchResultsForMultipleQuery = { __typename?: 'Query' } & {
    searchAcrossEntities?: Types.Maybe<{ __typename?: 'SearchResults' } & SearchResultsFragment>;
};

export type SearchAcrossLineageQueryVariables = Types.Exact<{
    input: Types.SearchAcrossLineageInput;
}>;

export type SearchAcrossLineageQuery = { __typename?: 'Query' } & {
    searchAcrossLineage?: Types.Maybe<
        { __typename?: 'SearchAcrossLineageResults' } & SearchAcrossRelationshipResultsFragment
    >;
};

export const SearchResultFieldsFragmentDoc = gql`
    fragment searchResultFields on Entity {
        urn
        type
        ... on Dataset {
            name
            origin
            uri
            platform {
                ...platformFields
            }
            dataPlatformInstance {
                ...dataPlatformInstanceFields
            }
            editableProperties {
                description
            }
            platformNativeType
            properties {
                name
                description
                qualifiedName
                customProperties {
                    key
                    value
                }
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            subTypes {
                typeNames
            }
            domain {
                ...entityDomain
            }
            container {
                ...entityContainer
            }
            parentContainers {
                ...parentContainersFields
            }
            deprecation {
                ...deprecationFields
            }
        }
        ... on CorpUser {
            username
            properties {
                active
                displayName
                title
                firstName
                lastName
                fullName
                email
            }
            info {
                active
                displayName
                title
                firstName
                lastName
                fullName
                email
            }
            editableProperties {
                displayName
                title
                pictureLink
            }
        }
        ... on CorpGroup {
            name
            info {
                displayName
                description
            }
            memberCount: relationships(input: { types: ["IsMemberOfGroup"], direction: INCOMING, start: 0, count: 1 }) {
                total
            }
        }
        ... on Dashboard {
            urn
            type
            tool
            dashboardId
            properties {
                name
                description
                externalUrl
                access
                lastModified {
                    time
                }
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            editableProperties {
                description
            }
            platform {
                ...platformFields
            }
            dataPlatformInstance {
                ...dataPlatformInstanceFields
            }
            domain {
                ...entityDomain
            }
            container {
                ...entityContainer
            }
            deprecation {
                ...deprecationFields
            }
            parentContainers {
                ...parentContainersFields
            }
        }
        ... on Chart {
            urn
            type
            tool
            chartId
            properties {
                name
                description
                externalUrl
                type
                access
                lastModified {
                    time
                }
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            editableProperties {
                description
            }
            platform {
                ...platformFields
            }
            dataPlatformInstance {
                ...dataPlatformInstanceFields
            }
            domain {
                ...entityDomain
            }
            container {
                ...entityContainer
            }
            deprecation {
                ...deprecationFields
            }
            parentContainers {
                ...parentContainersFields
            }
        }
        ... on DataFlow {
            urn
            type
            orchestrator
            flowId
            cluster
            properties {
                name
                description
                project
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            editableProperties {
                description
            }
            platform {
                ...platformFields
            }
            dataPlatformInstance {
                ...dataPlatformInstanceFields
            }
            domain {
                ...entityDomain
            }
            deprecation {
                ...deprecationFields
            }
        }
        ... on DataJob {
            urn
            type
            dataFlow {
                ...nonRecursiveDataFlowFields
            }
            jobId
            ownership {
                ...ownershipFields
            }
            properties {
                name
                description
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            editableProperties {
                description
            }
            domain {
                ...entityDomain
            }
            deprecation {
                ...deprecationFields
            }
            dataPlatformInstance {
                ...dataPlatformInstanceFields
            }
        }
        ... on GlossaryTerm {
            name
            hierarchicalName
            properties {
                name
                description
                termSource
                sourceRef
                sourceUrl
                rawSchema
                customProperties {
                    key
                    value
                }
            }
            deprecation {
                ...deprecationFields
            }
        }
        ... on Domain {
            urn
            properties {
                name
                description
            }
            ownership {
                ...ownershipFields
            }
        }
        ... on Container {
            urn
            properties {
                name
                description
            }
            platform {
                ...platformFields
            }
            dataPlatformInstance {
                ...dataPlatformInstanceFields
            }
            editableProperties {
                description
            }
            ownership {
                ...ownershipFields
            }
            tags {
                ...globalTagsFields
            }
            institutionalMemory {
                ...institutionalMemoryFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            subTypes {
                typeNames
            }
            entities(input: {}) {
                total
            }
            container {
                ...entityContainer
            }
            deprecation {
                ...deprecationFields
            }
            parentContainers {
                ...parentContainersFields
            }
        }
        ... on MLFeatureTable {
            urn
            type
            name
            description
            featureTableProperties {
                description
                mlFeatures {
                    urn
                }
                mlPrimaryKeys {
                    urn
                }
            }
            ownership {
                ...ownershipFields
            }
            platform {
                ...platformFields
            }
            deprecation {
                ...deprecationFields
            }
            dataPlatformInstance {
                ...dataPlatformInstanceFields
            }
        }
        ... on MLFeature {
            ...nonRecursiveMLFeature
        }
        ... on MLPrimaryKey {
            ...nonRecursiveMLPrimaryKey
        }
        ... on MLModel {
            name
            description
            origin
            ownership {
                ...ownershipFields
            }
            platform {
                ...platformFields
            }
            deprecation {
                ...deprecationFields
            }
            dataPlatformInstance {
                ...dataPlatformInstanceFields
            }
        }
        ... on MLModelGroup {
            name
            origin
            description
            ownership {
                ...ownershipFields
            }
            platform {
                ...platformFields
            }
            deprecation {
                ...deprecationFields
            }
            dataPlatformInstance {
                ...dataPlatformInstanceFields
            }
        }
        ... on Tag {
            name
            description
        }
    }
    ${PlatformFieldsFragmentDoc}
    ${DataPlatformInstanceFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${EntityDomainFragmentDoc}
    ${EntityContainerFragmentDoc}
    ${ParentContainersFieldsFragmentDoc}
    ${DeprecationFieldsFragmentDoc}
    ${NonRecursiveDataFlowFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
    ${NonRecursiveMlFeatureFragmentDoc}
    ${NonRecursiveMlPrimaryKeyFragmentDoc}
`;
export const FacetFieldsFragmentDoc = gql`
    fragment facetFields on FacetMetadata {
        field
        displayName
        aggregations {
            value
            count
            entity {
                urn
                type
                ... on Tag {
                    name
                    description
                    properties {
                        colorHex
                    }
                }
                ... on GlossaryTerm {
                    properties {
                        name
                    }
                }
                ... on DataPlatform {
                    ...platformFields
                }
                ... on DataPlatformInstance {
                    ...dataPlatformInstanceFields
                }
                ... on Domain {
                    urn
                    properties {
                        name
                    }
                }
                ... on Container {
                    urn
                    platform {
                        ...platformFields
                    }
                    properties {
                        name
                    }
                }
                ... on CorpUser {
                    urn
                    username
                    properties {
                        displayName
                        fullName
                    }
                    editableProperties {
                        displayName
                        pictureLink
                    }
                }
                ... on CorpGroup {
                    urn
                    name
                    properties {
                        displayName
                    }
                }
            }
        }
    }
    ${PlatformFieldsFragmentDoc}
    ${DataPlatformInstanceFieldsFragmentDoc}
`;
export const SearchResultsFragmentDoc = gql`
    fragment searchResults on SearchResults {
        start
        count
        total
        searchResults {
            entity {
                ...searchResultFields
            }
            matchedFields {
                name
                value
            }
            insights {
                text
                icon
            }
        }
        facets {
            ...facetFields
        }
    }
    ${SearchResultFieldsFragmentDoc}
    ${FacetFieldsFragmentDoc}
`;
export const SearchAcrossRelationshipResultsFragmentDoc = gql`
    fragment searchAcrossRelationshipResults on SearchAcrossLineageResults {
        start
        count
        total
        searchResults {
            entity {
                ...searchResultFields
            }
            matchedFields {
                name
                value
            }
            insights {
                text
                icon
            }
            degree
        }
        facets {
            ...facetFields
        }
    }
    ${SearchResultFieldsFragmentDoc}
    ${FacetFieldsFragmentDoc}
`;
export const GetAutoCompleteResultsDocument = gql`
    query getAutoCompleteResults($input: AutoCompleteInput!) {
        autoComplete(input: $input) {
            query
            suggestions
            entities {
                ...searchResultFields
            }
        }
    }
    ${SearchResultFieldsFragmentDoc}
`;

/**
 * __useGetAutoCompleteResultsQuery__
 *
 * To run a query within a React component, call `useGetAutoCompleteResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoCompleteResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoCompleteResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAutoCompleteResultsQuery(
    baseOptions: Apollo.QueryHookOptions<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>,
) {
    return Apollo.useQuery<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>(
        GetAutoCompleteResultsDocument,
        baseOptions,
    );
}
export function useGetAutoCompleteResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>(
        GetAutoCompleteResultsDocument,
        baseOptions,
    );
}
export type GetAutoCompleteResultsQueryHookResult = ReturnType<typeof useGetAutoCompleteResultsQuery>;
export type GetAutoCompleteResultsLazyQueryHookResult = ReturnType<typeof useGetAutoCompleteResultsLazyQuery>;
export type GetAutoCompleteResultsQueryResult = Apollo.QueryResult<
    GetAutoCompleteResultsQuery,
    GetAutoCompleteResultsQueryVariables
>;
export const GetAutoCompleteMultipleResultsDocument = gql`
    query getAutoCompleteMultipleResults($input: AutoCompleteMultipleInput!) {
        autoCompleteForMultiple(input: $input) {
            query
            suggestions {
                type
                suggestions
                entities {
                    ...searchResultFields
                }
            }
        }
    }
    ${SearchResultFieldsFragmentDoc}
`;

/**
 * __useGetAutoCompleteMultipleResultsQuery__
 *
 * To run a query within a React component, call `useGetAutoCompleteMultipleResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoCompleteMultipleResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoCompleteMultipleResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAutoCompleteMultipleResultsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAutoCompleteMultipleResultsQuery,
        GetAutoCompleteMultipleResultsQueryVariables
    >,
) {
    return Apollo.useQuery<GetAutoCompleteMultipleResultsQuery, GetAutoCompleteMultipleResultsQueryVariables>(
        GetAutoCompleteMultipleResultsDocument,
        baseOptions,
    );
}
export function useGetAutoCompleteMultipleResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAutoCompleteMultipleResultsQuery,
        GetAutoCompleteMultipleResultsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAutoCompleteMultipleResultsQuery, GetAutoCompleteMultipleResultsQueryVariables>(
        GetAutoCompleteMultipleResultsDocument,
        baseOptions,
    );
}
export type GetAutoCompleteMultipleResultsQueryHookResult = ReturnType<typeof useGetAutoCompleteMultipleResultsQuery>;
export type GetAutoCompleteMultipleResultsLazyQueryHookResult = ReturnType<
    typeof useGetAutoCompleteMultipleResultsLazyQuery
>;
export type GetAutoCompleteMultipleResultsQueryResult = Apollo.QueryResult<
    GetAutoCompleteMultipleResultsQuery,
    GetAutoCompleteMultipleResultsQueryVariables
>;
export const GetSearchResultsDocument = gql`
    query getSearchResults($input: SearchInput!) {
        search(input: $input) {
            ...searchResults
        }
    }
    ${SearchResultsFragmentDoc}
`;

/**
 * __useGetSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSearchResultsQuery(
    baseOptions: Apollo.QueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>,
) {
    return Apollo.useQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(
        GetSearchResultsDocument,
        baseOptions,
    );
}
export function useGetSearchResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(
        GetSearchResultsDocument,
        baseOptions,
    );
}
export type GetSearchResultsQueryHookResult = ReturnType<typeof useGetSearchResultsQuery>;
export type GetSearchResultsLazyQueryHookResult = ReturnType<typeof useGetSearchResultsLazyQuery>;
export type GetSearchResultsQueryResult = Apollo.QueryResult<GetSearchResultsQuery, GetSearchResultsQueryVariables>;
export const GetSearchResultsForMultipleDocument = gql`
    query getSearchResultsForMultiple($input: SearchAcrossEntitiesInput!) {
        searchAcrossEntities(input: $input) {
            ...searchResults
        }
    }
    ${SearchResultsFragmentDoc}
`;

/**
 * __useGetSearchResultsForMultipleQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsForMultipleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsForMultipleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsForMultipleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSearchResultsForMultipleQuery(
    baseOptions: Apollo.QueryHookOptions<GetSearchResultsForMultipleQuery, GetSearchResultsForMultipleQueryVariables>,
) {
    return Apollo.useQuery<GetSearchResultsForMultipleQuery, GetSearchResultsForMultipleQueryVariables>(
        GetSearchResultsForMultipleDocument,
        baseOptions,
    );
}
export function useGetSearchResultsForMultipleLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetSearchResultsForMultipleQuery,
        GetSearchResultsForMultipleQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetSearchResultsForMultipleQuery, GetSearchResultsForMultipleQueryVariables>(
        GetSearchResultsForMultipleDocument,
        baseOptions,
    );
}
export type GetSearchResultsForMultipleQueryHookResult = ReturnType<typeof useGetSearchResultsForMultipleQuery>;
export type GetSearchResultsForMultipleLazyQueryHookResult = ReturnType<typeof useGetSearchResultsForMultipleLazyQuery>;
export type GetSearchResultsForMultipleQueryResult = Apollo.QueryResult<
    GetSearchResultsForMultipleQuery,
    GetSearchResultsForMultipleQueryVariables
>;
export const SearchAcrossLineageDocument = gql`
    query searchAcrossLineage($input: SearchAcrossLineageInput!) {
        searchAcrossLineage(input: $input) {
            ...searchAcrossRelationshipResults
        }
    }
    ${SearchAcrossRelationshipResultsFragmentDoc}
`;

/**
 * __useSearchAcrossLineageQuery__
 *
 * To run a query within a React component, call `useSearchAcrossLineageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAcrossLineageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAcrossLineageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchAcrossLineageQuery(
    baseOptions: Apollo.QueryHookOptions<SearchAcrossLineageQuery, SearchAcrossLineageQueryVariables>,
) {
    return Apollo.useQuery<SearchAcrossLineageQuery, SearchAcrossLineageQueryVariables>(
        SearchAcrossLineageDocument,
        baseOptions,
    );
}
export function useSearchAcrossLineageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SearchAcrossLineageQuery, SearchAcrossLineageQueryVariables>,
) {
    return Apollo.useLazyQuery<SearchAcrossLineageQuery, SearchAcrossLineageQueryVariables>(
        SearchAcrossLineageDocument,
        baseOptions,
    );
}
export type SearchAcrossLineageQueryHookResult = ReturnType<typeof useSearchAcrossLineageQuery>;
export type SearchAcrossLineageLazyQueryHookResult = ReturnType<typeof useSearchAcrossLineageLazyQuery>;
export type SearchAcrossLineageQueryResult = Apollo.QueryResult<
    SearchAcrossLineageQuery,
    SearchAcrossLineageQueryVariables
>;
