/* eslint-disable */
import * as Types from '../types.generated';

import {
    DataJobFieldsFragment,
    DeprecationFieldsFragment,
    OwnershipFieldsFragment,
    InstitutionalMemoryFieldsFragment,
    SchemaMetadataFieldsFragment,
    PlatformFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
    DataPlatformInstanceFieldsFragment,
    EntityContainerFragment,
    ParentContainersFieldsFragment,
    DashboardFieldsFragment,
    NonRecursiveMlFeatureTableFragment,
    NonRecursiveMlFeatureFragment,
    NonRecursiveMlPrimaryKeyFragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    DataJobFieldsFragmentDoc,
    DeprecationFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    InstitutionalMemoryFieldsFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    PlatformFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
    DataPlatformInstanceFieldsFragmentDoc,
    EntityContainerFragmentDoc,
    ParentContainersFieldsFragmentDoc,
    DashboardFieldsFragmentDoc,
    NonRecursiveMlFeatureTableFragmentDoc,
    NonRecursiveMlFeatureFragmentDoc,
    NonRecursiveMlPrimaryKeyFragmentDoc,
} from './fragments.generated';
export type RelationshipFields_Assertion_Fragment = { __typename?: 'Assertion' } & Pick<
    Types.Assertion,
    'urn' | 'type'
> & {
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    };

export type RelationshipFields_Chart_Fragment = { __typename?: 'Chart' } & Pick<
    Types.Chart,
    'tool' | 'chartId' | 'urn' | 'type'
> & {
        properties?: Types.Maybe<
            { __typename?: 'ChartProperties' } & Pick<Types.ChartProperties, 'name' | 'description'>
        >;
        editableProperties?: Types.Maybe<
            { __typename?: 'ChartEditableProperties' } & Pick<Types.ChartEditableProperties, 'description'>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
        status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    };

export type RelationshipFields_Dashboard_Fragment = { __typename?: 'Dashboard' } & Pick<
    Types.Dashboard,
    'urn' | 'type'
> & {
        editableProperties?: Types.Maybe<
            { __typename?: 'DashboardEditableProperties' } & Pick<Types.DashboardEditableProperties, 'description'>
        >;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    } & DashboardFieldsFragment;

export type RelationshipFields_DataFlow_Fragment = { __typename?: 'DataFlow' } & Pick<
    Types.DataFlow,
    'orchestrator' | 'flowId' | 'cluster' | 'urn' | 'type'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DataFlowProperties' } & Pick<Types.DataFlowProperties, 'name' | 'description' | 'project'>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        editableProperties?: Types.Maybe<
            { __typename?: 'DataFlowEditableProperties' } & Pick<Types.DataFlowEditableProperties, 'description'>
        >;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
        status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    };

export type RelationshipFields_DataJob_Fragment = { __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'> & {
        editableProperties?: Types.Maybe<
            { __typename?: 'DataJobEditableProperties' } & Pick<Types.DataJobEditableProperties, 'description'>
        >;
        status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    } & DataJobFieldsFragment;

export type RelationshipFields_DataProcessInstance_Fragment = { __typename?: 'DataProcessInstance' } & Pick<
    Types.DataProcessInstance,
    'urn' | 'type'
> & {
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    };

export type RelationshipFields_Dataset_Fragment = { __typename?: 'Dataset' } & Pick<
    Types.Dataset,
    'name' | 'urn' | 'type'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DatasetProperties' } & Pick<Types.DatasetProperties, 'name' | 'description'>
        >;
        editableProperties?: Types.Maybe<
            { __typename?: 'DatasetEditableProperties' } & Pick<Types.DatasetEditableProperties, 'description'>
        >;
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        subTypes?: Types.Maybe<{ __typename?: 'SubTypes' } & Pick<Types.SubTypes, 'typeNames'>>;
        status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    };

export type RelationshipFields_MlFeature_Fragment = { __typename?: 'MLFeature' } & Pick<
    Types.MlFeature,
    'urn' | 'type'
> & {
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    } & NonRecursiveMlFeatureFragment;

export type RelationshipFields_MlFeatureTable_Fragment = { __typename?: 'MLFeatureTable' } & Pick<
    Types.MlFeatureTable,
    'urn' | 'type'
> & {
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    } & NonRecursiveMlFeatureTableFragment;

export type RelationshipFields_MlModel_Fragment = { __typename?: 'MLModel' } & Pick<
    Types.MlModel,
    'urn' | 'type' | 'name' | 'description' | 'origin'
> & {
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    };

export type RelationshipFields_MlModelGroup_Fragment = { __typename?: 'MLModelGroup' } & Pick<
    Types.MlModelGroup,
    'urn' | 'type' | 'name' | 'description' | 'origin'
> & {
        platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    };

export type RelationshipFields_MlPrimaryKey_Fragment = { __typename?: 'MLPrimaryKey' } & Pick<
    Types.MlPrimaryKey,
    'urn' | 'type'
> & {
        upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
        downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & LeafLineageResultsFragment>;
    } & NonRecursiveMlPrimaryKeyFragment;

export type RelationshipFieldsFragment =
    | RelationshipFields_Assertion_Fragment
    | RelationshipFields_Chart_Fragment
    | RelationshipFields_Dashboard_Fragment
    | RelationshipFields_DataFlow_Fragment
    | RelationshipFields_DataJob_Fragment
    | RelationshipFields_DataProcessInstance_Fragment
    | RelationshipFields_Dataset_Fragment
    | RelationshipFields_MlFeature_Fragment
    | RelationshipFields_MlFeatureTable_Fragment
    | RelationshipFields_MlModel_Fragment
    | RelationshipFields_MlModelGroup_Fragment
    | RelationshipFields_MlPrimaryKey_Fragment;

export type FullLineageResultsFragment = { __typename?: 'EntityLineageResult' } & Pick<
    Types.EntityLineageResult,
    'start' | 'count' | 'total'
> & {
        relationships: Array<
            { __typename?: 'LineageRelationship' } & Pick<Types.LineageRelationship, 'type'> & {
                    entity?: Types.Maybe<
                        | ({ __typename?: 'Assertion' } & RelationshipFields_Assertion_Fragment)
                        | ({ __typename?: 'Chart' } & RelationshipFields_Chart_Fragment)
                        | { __typename?: 'Container' }
                        | { __typename?: 'CorpGroup' }
                        | { __typename?: 'CorpUser' }
                        | ({ __typename?: 'Dashboard' } & RelationshipFields_Dashboard_Fragment)
                        | ({ __typename?: 'DataFlow' } & RelationshipFields_DataFlow_Fragment)
                        | ({ __typename?: 'DataJob' } & RelationshipFields_DataJob_Fragment)
                        | { __typename?: 'DataPlatform' }
                        | { __typename?: 'DataPlatformInstance' }
                        | ({ __typename?: 'DataProcessInstance' } & RelationshipFields_DataProcessInstance_Fragment)
                        | ({ __typename?: 'Dataset' } & RelationshipFields_Dataset_Fragment)
                        | { __typename?: 'Domain' }
                        | { __typename?: 'GlossaryTerm' }
                        | ({ __typename?: 'MLFeature' } & RelationshipFields_MlFeature_Fragment)
                        | ({ __typename?: 'MLFeatureTable' } & RelationshipFields_MlFeatureTable_Fragment)
                        | ({ __typename?: 'MLModel' } & RelationshipFields_MlModel_Fragment)
                        | ({ __typename?: 'MLModelGroup' } & RelationshipFields_MlModelGroup_Fragment)
                        | ({ __typename?: 'MLPrimaryKey' } & RelationshipFields_MlPrimaryKey_Fragment)
                        | { __typename?: 'Notebook' }
                        | { __typename?: 'Tag' }
                        | { __typename?: 'VersionedDataset' }
                    >;
                }
        >;
    };

export type LeafLineageResultsFragment = { __typename?: 'EntityLineageResult' } & Pick<
    Types.EntityLineageResult,
    'start' | 'count' | 'total'
> & {
        relationships: Array<
            { __typename?: 'LineageRelationship' } & Pick<Types.LineageRelationship, 'type'> & {
                    entity?: Types.Maybe<
                        | ({ __typename?: 'Assertion' } & Pick<Types.Assertion, 'urn' | 'type'>)
                        | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                        | ({ __typename?: 'Container' } & Pick<Types.Container, 'urn' | 'type'>)
                        | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'type'>)
                        | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'type'>)
                        | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                        | ({ __typename?: 'DataFlow' } & Pick<Types.DataFlow, 'urn' | 'type'>)
                        | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                        | ({ __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'urn' | 'type'>)
                        | ({ __typename?: 'DataPlatformInstance' } & Pick<Types.DataPlatformInstance, 'urn' | 'type'>)
                        | ({ __typename?: 'DataProcessInstance' } & Pick<Types.DataProcessInstance, 'urn' | 'type'>)
                        | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                        | ({ __typename?: 'Domain' } & Pick<Types.Domain, 'urn' | 'type'>)
                        | ({ __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'urn' | 'type'>)
                        | ({ __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn' | 'type'>)
                        | ({ __typename?: 'MLFeatureTable' } & Pick<Types.MlFeatureTable, 'urn' | 'type'>)
                        | ({ __typename?: 'MLModel' } & Pick<Types.MlModel, 'urn' | 'type'>)
                        | ({ __typename?: 'MLModelGroup' } & Pick<Types.MlModelGroup, 'urn' | 'type'>)
                        | ({ __typename?: 'MLPrimaryKey' } & Pick<Types.MlPrimaryKey, 'urn' | 'type'>)
                        | ({ __typename?: 'Notebook' } & Pick<Types.Notebook, 'urn' | 'type'>)
                        | ({ __typename?: 'Tag' } & Pick<Types.Tag, 'urn' | 'type'>)
                        | ({ __typename?: 'VersionedDataset' } & Pick<Types.VersionedDataset, 'urn' | 'type'>)
                    >;
                }
        >;
    };

export const LeafLineageResultsFragmentDoc = gql`
    fragment leafLineageResults on EntityLineageResult {
        start
        count
        total
        relationships {
            type
            entity {
                urn
                type
            }
        }
    }
`;
export const RelationshipFieldsFragmentDoc = gql`
    fragment relationshipFields on EntityWithRelationships {
        urn
        type
        ... on DataJob {
            ...dataJobFields
            editableProperties {
                description
            }
            status {
                removed
            }
        }
        ... on DataFlow {
            orchestrator
            flowId
            cluster
            properties {
                name
                description
                project
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            editableProperties {
                description
            }
            platform {
                ...platformFields
            }
            domain {
                ...entityDomain
            }
            status {
                removed
            }
        }
        ... on Dashboard {
            ...dashboardFields
            editableProperties {
                description
            }
            platform {
                ...platformFields
            }
            status {
                removed
            }
        }
        ... on Chart {
            tool
            chartId
            properties {
                name
                description
            }
            editableProperties {
                description
            }
            ownership {
                ...ownershipFields
            }
            platform {
                ...platformFields
            }
            domain {
                ...entityDomain
            }
            status {
                removed
            }
        }
        ... on Dataset {
            name
            properties {
                name
                description
            }
            editableProperties {
                description
            }
            platform {
                ...platformFields
            }
            ownership {
                ...ownershipFields
            }
            subTypes {
                typeNames
            }
            status {
                removed
            }
        }
        ... on MLModelGroup {
            urn
            type
            name
            description
            origin
            platform {
                ...platformFields
            }
            ownership {
                ...ownershipFields
            }
            status {
                removed
            }
        }
        ... on MLModel {
            urn
            type
            name
            description
            origin
            platform {
                ...platformFields
            }
            ownership {
                ...ownershipFields
            }
            status {
                removed
            }
        }
        ... on MLFeatureTable {
            ...nonRecursiveMLFeatureTable
        }
        ... on MLFeature {
            ...nonRecursiveMLFeature
        }
        ... on MLPrimaryKey {
            ...nonRecursiveMLPrimaryKey
        }
        upstream: lineage(input: { direction: UPSTREAM, start: 0, count: 100 }) {
            ...leafLineageResults
        }
        downstream: lineage(input: { direction: DOWNSTREAM, start: 0, count: 100 }) {
            ...leafLineageResults
        }
    }
    ${DataJobFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${PlatformFieldsFragmentDoc}
    ${EntityDomainFragmentDoc}
    ${DashboardFieldsFragmentDoc}
    ${NonRecursiveMlFeatureTableFragmentDoc}
    ${NonRecursiveMlFeatureFragmentDoc}
    ${NonRecursiveMlPrimaryKeyFragmentDoc}
    ${LeafLineageResultsFragmentDoc}
`;
export const FullLineageResultsFragmentDoc = gql`
    fragment fullLineageResults on EntityLineageResult {
        start
        count
        total
        relationships {
            type
            entity {
                ...relationshipFields
            }
        }
    }
    ${RelationshipFieldsFragmentDoc}
`;
