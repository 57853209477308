/* eslint-disable */
import * as Types from '../types.generated';

import { FullRelationshipResultsFragment } from './relationships.generated';
import { gql } from '@apollo/client';
import { FullRelationshipResultsFragmentDoc } from './relationships.generated';
export type RunResultsFragment = { __typename?: 'DataProcessInstanceResult' } & Pick<
    Types.DataProcessInstanceResult,
    'count' | 'start' | 'total'
> & {
        runs?: Types.Maybe<
            Array<
                Types.Maybe<
                    { __typename?: 'DataProcessInstance' } & Pick<
                        Types.DataProcessInstance,
                        'urn' | 'type' | 'name' | 'externalUrl'
                    > & {
                            created?: Types.Maybe<
                                { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time' | 'actor'>
                            >;
                            state?: Types.Maybe<
                                Array<
                                    Types.Maybe<
                                        { __typename?: 'DataProcessRunEvent' } & Pick<
                                            Types.DataProcessRunEvent,
                                            'status' | 'attempt' | 'timestampMillis'
                                        > & {
                                                result?: Types.Maybe<
                                                    { __typename?: 'DataProcessInstanceRunResult' } & Pick<
                                                        Types.DataProcessInstanceRunResult,
                                                        'resultType' | 'nativeResultType'
                                                    >
                                                >;
                                            }
                                    >
                                >
                            >;
                            inputs?: Types.Maybe<
                                { __typename?: 'EntityRelationshipsResult' } & FullRelationshipResultsFragment
                            >;
                            outputs?: Types.Maybe<
                                { __typename?: 'EntityRelationshipsResult' } & FullRelationshipResultsFragment
                            >;
                            parentTemplate?: Types.Maybe<
                                { __typename?: 'EntityRelationshipsResult' } & FullRelationshipResultsFragment
                            >;
                        }
                >
            >
        >;
    };

export const RunResultsFragmentDoc = gql`
    fragment runResults on DataProcessInstanceResult {
        count
        start
        total
        runs {
            urn
            type
            created {
                time
                actor
            }
            name
            state(startTimeMillis: null, endTimeMillis: null, limit: 1) {
                status
                attempt
                result {
                    resultType
                    nativeResultType
                }
                timestampMillis
            }
            inputs: relationships(input: { types: ["Consumes"], direction: OUTGOING, start: 0, count: 20 }) {
                ...fullRelationshipResults
            }
            outputs: relationships(input: { types: ["Produces"], direction: OUTGOING, start: 0, count: 20 }) {
                ...fullRelationshipResults
            }
            parentTemplate: relationships(input: { types: ["InstanceOf"], direction: OUTGOING, start: 0, count: 1 }) {
                ...fullRelationshipResults
            }
            externalUrl
        }
    }
    ${FullRelationshipResultsFragmentDoc}
`;
