/* eslint-disable */
import * as Types from '../types.generated';

import {
    RelationshipFields_Assertion_Fragment,
    RelationshipFields_Chart_Fragment,
    RelationshipFields_Dashboard_Fragment,
    RelationshipFields_DataFlow_Fragment,
    RelationshipFields_DataJob_Fragment,
    RelationshipFields_DataProcessInstance_Fragment,
    RelationshipFields_Dataset_Fragment,
    RelationshipFields_MlFeature_Fragment,
    RelationshipFields_MlFeatureTable_Fragment,
    RelationshipFields_MlModel_Fragment,
    RelationshipFields_MlModelGroup_Fragment,
    RelationshipFields_MlPrimaryKey_Fragment,
} from './lineage.generated';
import { gql } from '@apollo/client';
import { RelationshipFieldsFragmentDoc } from './lineage.generated';
export type FullRelationshipResultsFragment = { __typename?: 'EntityRelationshipsResult' } & Pick<
    Types.EntityRelationshipsResult,
    'start' | 'count' | 'total'
> & {
        relationships: Array<
            { __typename?: 'EntityRelationship' } & Pick<Types.EntityRelationship, 'type' | 'direction'> & {
                    entity?: Types.Maybe<
                        | ({ __typename?: 'Assertion' } & RelationshipFields_Assertion_Fragment)
                        | ({ __typename?: 'Chart' } & RelationshipFields_Chart_Fragment)
                        | { __typename?: 'Container' }
                        | { __typename?: 'CorpGroup' }
                        | { __typename?: 'CorpUser' }
                        | ({ __typename?: 'Dashboard' } & RelationshipFields_Dashboard_Fragment)
                        | ({ __typename?: 'DataFlow' } & RelationshipFields_DataFlow_Fragment)
                        | ({ __typename?: 'DataJob' } & RelationshipFields_DataJob_Fragment)
                        | { __typename?: 'DataPlatform' }
                        | { __typename?: 'DataPlatformInstance' }
                        | ({ __typename?: 'DataProcessInstance' } & RelationshipFields_DataProcessInstance_Fragment)
                        | ({ __typename?: 'Dataset' } & RelationshipFields_Dataset_Fragment)
                        | { __typename?: 'Domain' }
                        | { __typename?: 'GlossaryTerm' }
                        | ({ __typename?: 'MLFeature' } & RelationshipFields_MlFeature_Fragment)
                        | ({ __typename?: 'MLFeatureTable' } & RelationshipFields_MlFeatureTable_Fragment)
                        | ({ __typename?: 'MLModel' } & RelationshipFields_MlModel_Fragment)
                        | ({ __typename?: 'MLModelGroup' } & RelationshipFields_MlModelGroup_Fragment)
                        | ({ __typename?: 'MLPrimaryKey' } & RelationshipFields_MlPrimaryKey_Fragment)
                        | { __typename?: 'Notebook' }
                        | { __typename?: 'Tag' }
                        | { __typename?: 'VersionedDataset' }
                    >;
                }
        >;
    };

export type LeafRelationshipResultsFragment = { __typename?: 'EntityRelationshipsResult' } & Pick<
    Types.EntityRelationshipsResult,
    'start' | 'count' | 'total'
> & {
        relationships: Array<
            { __typename?: 'EntityRelationship' } & Pick<Types.EntityRelationship, 'type'> & {
                    entity?: Types.Maybe<
                        | ({ __typename?: 'Assertion' } & Pick<Types.Assertion, 'urn' | 'type'>)
                        | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                        | ({ __typename?: 'Container' } & Pick<Types.Container, 'urn' | 'type'>)
                        | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'type'>)
                        | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'type'>)
                        | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                        | ({ __typename?: 'DataFlow' } & Pick<Types.DataFlow, 'urn' | 'type'>)
                        | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                        | ({ __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'urn' | 'type'>)
                        | ({ __typename?: 'DataPlatformInstance' } & Pick<Types.DataPlatformInstance, 'urn' | 'type'>)
                        | ({ __typename?: 'DataProcessInstance' } & Pick<Types.DataProcessInstance, 'urn' | 'type'>)
                        | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                        | ({ __typename?: 'Domain' } & Pick<Types.Domain, 'urn' | 'type'>)
                        | ({ __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'urn' | 'type'>)
                        | ({ __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn' | 'type'>)
                        | ({ __typename?: 'MLFeatureTable' } & Pick<Types.MlFeatureTable, 'urn' | 'type'>)
                        | ({ __typename?: 'MLModel' } & Pick<Types.MlModel, 'urn' | 'type'>)
                        | ({ __typename?: 'MLModelGroup' } & Pick<Types.MlModelGroup, 'urn' | 'type'>)
                        | ({ __typename?: 'MLPrimaryKey' } & Pick<Types.MlPrimaryKey, 'urn' | 'type'>)
                        | ({ __typename?: 'Notebook' } & Pick<Types.Notebook, 'urn' | 'type'>)
                        | ({ __typename?: 'Tag' } & Pick<Types.Tag, 'urn' | 'type'>)
                        | ({ __typename?: 'VersionedDataset' } & Pick<Types.VersionedDataset, 'urn' | 'type'>)
                    >;
                }
        >;
    };

export const FullRelationshipResultsFragmentDoc = gql`
    fragment fullRelationshipResults on EntityRelationshipsResult {
        start
        count
        total
        relationships {
            type
            direction
            entity {
                ...relationshipFields
            }
        }
    }
    ${RelationshipFieldsFragmentDoc}
`;
export const LeafRelationshipResultsFragmentDoc = gql`
    fragment leafRelationshipResults on EntityRelationshipsResult {
        start
        count
        total
        relationships {
            type
            entity {
                urn
                type
            }
        }
    }
`;
