/* eslint-disable */
import * as Types from '../types.generated';

import {
    NonRecursiveDatasetFieldsFragment,
    SchemaMetadataFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
    EntityContainerFragment,
    ParentContainersFieldsFragment,
} from './fragments.generated';
import { FullLineageResultsFragment } from './lineage.generated';
import { AssertionDetailsFragment, AssertionRunEventDetailsFragment } from './assertion.generated';
import { RunResultsFragment } from './dataProcess.generated';
import { gql } from '@apollo/client';
import {
    NonRecursiveDatasetFieldsFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
    EntityContainerFragmentDoc,
    ParentContainersFieldsFragmentDoc,
} from './fragments.generated';
import { FullLineageResultsFragmentDoc } from './lineage.generated';
import { AssertionDetailsFragmentDoc, AssertionRunEventDetailsFragmentDoc } from './assertion.generated';
import { RunResultsFragmentDoc } from './dataProcess.generated';
import * as Apollo from '@apollo/client';
export type GetDataProfilesQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
    limit?: Types.Maybe<Types.Scalars['Int']>;
    startTime?: Types.Maybe<Types.Scalars['Long']>;
    endTime?: Types.Maybe<Types.Scalars['Long']>;
}>;

export type GetDataProfilesQuery = { __typename?: 'Query' } & {
    dataset?: Types.Maybe<
        { __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn'> & {
                datasetProfiles?: Types.Maybe<
                    Array<
                        { __typename?: 'DatasetProfile' } & Pick<
                            Types.DatasetProfile,
                            'rowCount' | 'columnCount' | 'timestampMillis'
                        > & {
                                fieldProfiles?: Types.Maybe<
                                    Array<
                                        { __typename?: 'DatasetFieldProfile' } & Pick<
                                            Types.DatasetFieldProfile,
                                            | 'fieldPath'
                                            | 'uniqueCount'
                                            | 'uniqueProportion'
                                            | 'nullCount'
                                            | 'nullProportion'
                                            | 'min'
                                            | 'max'
                                            | 'mean'
                                            | 'median'
                                            | 'stdev'
                                            | 'sampleValues'
                                        >
                                    >
                                >;
                            }
                    >
                >;
            }
    >;
};

export type GetDatasetQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetDatasetQuery = { __typename?: 'Query' } & {
    dataset?: Types.Maybe<
        { __typename?: 'Dataset' } & {
            schemaMetadata?: Types.Maybe<{ __typename?: 'SchemaMetadata' } & SchemaMetadataFieldsFragment>;
            previousSchemaMetadata?: Types.Maybe<{ __typename?: 'SchemaMetadata' } & SchemaMetadataFieldsFragment>;
            editableSchemaMetadata?: Types.Maybe<
                { __typename?: 'EditableSchemaMetadata' } & {
                    editableSchemaFieldInfo: Array<
                        { __typename?: 'EditableSchemaFieldInfo' } & Pick<
                            Types.EditableSchemaFieldInfo,
                            'fieldPath' | 'description'
                        > & {
                                globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                                glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
                            }
                    >;
                }
            >;
            deprecation?: Types.Maybe<
                { __typename?: 'Deprecation' } & Pick<
                    Types.Deprecation,
                    'actor' | 'deprecated' | 'note' | 'decommissionTime'
                >
            >;
            globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
            glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
            subTypes?: Types.Maybe<{ __typename?: 'SubTypes' } & Pick<Types.SubTypes, 'typeNames'>>;
            domain?: Types.Maybe<{ __typename?: 'Domain' } & EntityDomainFragment>;
            container?: Types.Maybe<{ __typename?: 'Container' } & EntityContainerFragment>;
            parentContainers?: Types.Maybe<{ __typename?: 'ParentContainersResult' } & ParentContainersFieldsFragment>;
            usageStats?: Types.Maybe<
                { __typename?: 'UsageQueryResult' } & {
                    buckets?: Types.Maybe<
                        Array<
                            Types.Maybe<
                                { __typename?: 'UsageAggregation' } & Pick<
                                    Types.UsageAggregation,
                                    'bucket' | 'duration' | 'resource'
                                > & {
                                        metrics?: Types.Maybe<
                                            { __typename?: 'UsageAggregationMetrics' } & Pick<
                                                Types.UsageAggregationMetrics,
                                                'uniqueUserCount' | 'totalSqlQueries' | 'topSqlQueries'
                                            >
                                        >;
                                    }
                            >
                        >
                    >;
                    aggregations?: Types.Maybe<
                        { __typename?: 'UsageQueryResultAggregations' } & Pick<
                            Types.UsageQueryResultAggregations,
                            'uniqueUserCount' | 'totalSqlQueries'
                        > & {
                                users?: Types.Maybe<
                                    Array<
                                        Types.Maybe<
                                            { __typename?: 'UserUsageCounts' } & Pick<
                                                Types.UserUsageCounts,
                                                'count' | 'userEmail'
                                            > & {
                                                    user?: Types.Maybe<
                                                        { __typename?: 'CorpUser' } & Pick<
                                                            Types.CorpUser,
                                                            'urn' | 'username'
                                                        >
                                                    >;
                                                }
                                        >
                                    >
                                >;
                                fields?: Types.Maybe<
                                    Array<
                                        Types.Maybe<
                                            { __typename?: 'FieldUsageCounts' } & Pick<
                                                Types.FieldUsageCounts,
                                                'fieldName' | 'count'
                                            >
                                        >
                                    >
                                >;
                            }
                    >;
                }
            >;
            datasetProfiles?: Types.Maybe<
                Array<
                    { __typename?: 'DatasetProfile' } & Pick<
                        Types.DatasetProfile,
                        'rowCount' | 'columnCount' | 'timestampMillis'
                    > & {
                            fieldProfiles?: Types.Maybe<
                                Array<
                                    { __typename?: 'DatasetFieldProfile' } & Pick<
                                        Types.DatasetFieldProfile,
                                        | 'fieldPath'
                                        | 'uniqueCount'
                                        | 'uniqueProportion'
                                        | 'nullCount'
                                        | 'nullProportion'
                                        | 'min'
                                        | 'max'
                                        | 'mean'
                                        | 'median'
                                        | 'stdev'
                                        | 'sampleValues'
                                    >
                                >
                            >;
                        }
                >
            >;
            health?: Types.Maybe<{ __typename?: 'Health' } & Pick<Types.Health, 'status' | 'message' | 'causes'>>;
            assertions?: Types.Maybe<
                { __typename?: 'EntityAssertionsResult' } & Pick<Types.EntityAssertionsResult, 'total'>
            >;
            operations?: Types.Maybe<
                Array<{ __typename?: 'Operation' } & Pick<Types.Operation, 'timestampMillis' | 'lastUpdatedTimestamp'>>
            >;
            upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & FullLineageResultsFragment>;
            downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & FullLineageResultsFragment>;
            autoRenderAspects?: Types.Maybe<
                Array<
                    { __typename?: 'RawAspect' } & Pick<Types.RawAspect, 'aspectName' | 'payload'> & {
                            renderSpec?: Types.Maybe<
                                { __typename?: 'AspectRenderSpec' } & Pick<
                                    Types.AspectRenderSpec,
                                    'displayType' | 'displayName' | 'key'
                                >
                            >;
                        }
                >
            >;
            status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
            readRuns?: Types.Maybe<
                { __typename?: 'DataProcessInstanceResult' } & Pick<
                    Types.DataProcessInstanceResult,
                    'count' | 'start' | 'total'
                >
            >;
            writeRuns?: Types.Maybe<
                { __typename?: 'DataProcessInstanceResult' } & Pick<
                    Types.DataProcessInstanceResult,
                    'count' | 'start' | 'total'
                >
            >;
        } & NonRecursiveDatasetFieldsFragment &
            ViewPropertiesFragment
    >;
};

export type UpdateDatasetMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
    input: Types.DatasetUpdateInput;
}>;

export type UpdateDatasetMutation = { __typename?: 'Mutation' } & {
    updateDataset?: Types.Maybe<{ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn'>>;
};

export type ViewPropertiesFragment = { __typename?: 'Dataset' } & {
    viewProperties?: Types.Maybe<
        { __typename?: 'ViewProperties' } & Pick<Types.ViewProperties, 'materialized' | 'logic' | 'language'>
    >;
};

export type GetDatasetAssertionsQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetDatasetAssertionsQuery = { __typename?: 'Query' } & {
    dataset?: Types.Maybe<
        { __typename?: 'Dataset' } & {
            assertions?: Types.Maybe<
                { __typename?: 'EntityAssertionsResult' } & Pick<
                    Types.EntityAssertionsResult,
                    'start' | 'count' | 'total'
                > & {
                        assertions: Array<
                            { __typename?: 'Assertion' } & {
                                runEvents?: Types.Maybe<
                                    { __typename?: 'AssertionRunEventsResult' } & Pick<
                                        Types.AssertionRunEventsResult,
                                        'total' | 'failed' | 'succeeded'
                                    > & {
                                            runEvents: Array<
                                                { __typename?: 'AssertionRunEvent' } & AssertionRunEventDetailsFragment
                                            >;
                                        }
                                >;
                            } & AssertionDetailsFragment
                        >;
                    }
            >;
        }
    >;
};

export type GetDatasetRunsQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
    start: Types.Scalars['Int'];
    count: Types.Scalars['Int'];
    direction: Types.RelationshipDirection;
}>;

export type GetDatasetRunsQuery = { __typename?: 'Query' } & {
    dataset?: Types.Maybe<
        { __typename?: 'Dataset' } & {
            runs?: Types.Maybe<{ __typename?: 'DataProcessInstanceResult' } & RunResultsFragment>;
        }
    >;
};

export const ViewPropertiesFragmentDoc = gql`
    fragment viewProperties on Dataset {
        viewProperties {
            materialized
            logic
            language
        }
    }
`;
export const GetDataProfilesDocument = gql`
    query getDataProfiles($urn: String!, $limit: Int, $startTime: Long, $endTime: Long) {
        dataset(urn: $urn) {
            urn
            datasetProfiles(limit: $limit, startTimeMillis: $startTime, endTimeMillis: $endTime) {
                rowCount
                columnCount
                timestampMillis
                fieldProfiles {
                    fieldPath
                    uniqueCount
                    uniqueProportion
                    nullCount
                    nullProportion
                    min
                    max
                    mean
                    median
                    stdev
                    sampleValues
                }
            }
        }
    }
`;

/**
 * __useGetDataProfilesQuery__
 *
 * To run a query within a React component, call `useGetDataProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataProfilesQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *      limit: // value for 'limit'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetDataProfilesQuery(
    baseOptions: Apollo.QueryHookOptions<GetDataProfilesQuery, GetDataProfilesQueryVariables>,
) {
    return Apollo.useQuery<GetDataProfilesQuery, GetDataProfilesQueryVariables>(GetDataProfilesDocument, baseOptions);
}
export function useGetDataProfilesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDataProfilesQuery, GetDataProfilesQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDataProfilesQuery, GetDataProfilesQueryVariables>(
        GetDataProfilesDocument,
        baseOptions,
    );
}
export type GetDataProfilesQueryHookResult = ReturnType<typeof useGetDataProfilesQuery>;
export type GetDataProfilesLazyQueryHookResult = ReturnType<typeof useGetDataProfilesLazyQuery>;
export type GetDataProfilesQueryResult = Apollo.QueryResult<GetDataProfilesQuery, GetDataProfilesQueryVariables>;
export const GetDatasetDocument = gql`
    query getDataset($urn: String!) {
        dataset(urn: $urn) {
            ...nonRecursiveDatasetFields
            schemaMetadata(version: 0) {
                ...schemaMetadataFields
            }
            previousSchemaMetadata: schemaMetadata(version: -1) {
                ...schemaMetadataFields
            }
            editableSchemaMetadata {
                editableSchemaFieldInfo {
                    fieldPath
                    description
                    globalTags {
                        ...globalTagsFields
                    }
                    glossaryTerms {
                        ...glossaryTerms
                    }
                }
            }
            deprecation {
                actor
                deprecated
                note
                decommissionTime
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            subTypes {
                typeNames
            }
            domain {
                ...entityDomain
            }
            container {
                ...entityContainer
            }
            parentContainers {
                ...parentContainersFields
            }
            usageStats(range: MONTH) {
                buckets {
                    bucket
                    duration
                    resource
                    metrics {
                        uniqueUserCount
                        totalSqlQueries
                        topSqlQueries
                    }
                }
                aggregations {
                    uniqueUserCount
                    totalSqlQueries
                    users {
                        user {
                            urn
                            username
                        }
                        count
                        userEmail
                    }
                    fields {
                        fieldName
                        count
                    }
                }
            }
            datasetProfiles(limit: 1) {
                rowCount
                columnCount
                timestampMillis
                fieldProfiles {
                    fieldPath
                    uniqueCount
                    uniqueProportion
                    nullCount
                    nullProportion
                    min
                    max
                    mean
                    median
                    stdev
                    sampleValues
                }
            }
            health {
                status
                message
                causes
            }
            assertions(start: 0, count: 1) {
                total
            }
            operations(limit: 1) {
                timestampMillis
                lastUpdatedTimestamp
            }
            upstream: lineage(input: { direction: UPSTREAM, start: 0, count: 100 }) {
                ...fullLineageResults
            }
            downstream: lineage(input: { direction: DOWNSTREAM, start: 0, count: 100 }) {
                ...fullLineageResults
            }
            ...viewProperties
            autoRenderAspects: aspects(input: { autoRenderOnly: true }) {
                aspectName
                payload
                renderSpec {
                    displayType
                    displayName
                    key
                }
            }
            status {
                removed
            }
            readRuns: runs(start: 0, count: 20, direction: INCOMING) {
                count
                start
                total
            }
            writeRuns: runs(start: 0, count: 20, direction: OUTGOING) {
                count
                start
                total
            }
        }
    }
    ${NonRecursiveDatasetFieldsFragmentDoc}
    ${SchemaMetadataFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${EntityDomainFragmentDoc}
    ${EntityContainerFragmentDoc}
    ${ParentContainersFieldsFragmentDoc}
    ${FullLineageResultsFragmentDoc}
    ${ViewPropertiesFragmentDoc}
`;

/**
 * __useGetDatasetQuery__
 *
 * To run a query within a React component, call `useGetDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetDatasetQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetQuery, GetDatasetQueryVariables>) {
    return Apollo.useQuery<GetDatasetQuery, GetDatasetQueryVariables>(GetDatasetDocument, baseOptions);
}
export function useGetDatasetLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetQuery, GetDatasetQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDatasetQuery, GetDatasetQueryVariables>(GetDatasetDocument, baseOptions);
}
export type GetDatasetQueryHookResult = ReturnType<typeof useGetDatasetQuery>;
export type GetDatasetLazyQueryHookResult = ReturnType<typeof useGetDatasetLazyQuery>;
export type GetDatasetQueryResult = Apollo.QueryResult<GetDatasetQuery, GetDatasetQueryVariables>;
export const UpdateDatasetDocument = gql`
    mutation updateDataset($urn: String!, $input: DatasetUpdateInput!) {
        updateDataset(urn: $urn, input: $input) {
            urn
        }
    }
`;
export type UpdateDatasetMutationFn = Apollo.MutationFunction<UpdateDatasetMutation, UpdateDatasetMutationVariables>;

/**
 * __useUpdateDatasetMutation__
 *
 * To run a mutation, you first call `useUpdateDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatasetMutation, { data, loading, error }] = useUpdateDatasetMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDatasetMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateDatasetMutation, UpdateDatasetMutationVariables>,
) {
    return Apollo.useMutation<UpdateDatasetMutation, UpdateDatasetMutationVariables>(
        UpdateDatasetDocument,
        baseOptions,
    );
}
export type UpdateDatasetMutationHookResult = ReturnType<typeof useUpdateDatasetMutation>;
export type UpdateDatasetMutationResult = Apollo.MutationResult<UpdateDatasetMutation>;
export type UpdateDatasetMutationOptions = Apollo.BaseMutationOptions<
    UpdateDatasetMutation,
    UpdateDatasetMutationVariables
>;
export const GetDatasetAssertionsDocument = gql`
    query getDatasetAssertions($urn: String!) {
        dataset(urn: $urn) {
            assertions(start: 0, count: 100) {
                start
                count
                total
                assertions {
                    ...assertionDetails
                    runEvents(status: COMPLETE, limit: 1) {
                        total
                        failed
                        succeeded
                        runEvents {
                            ...assertionRunEventDetails
                        }
                    }
                }
            }
        }
    }
    ${AssertionDetailsFragmentDoc}
    ${AssertionRunEventDetailsFragmentDoc}
`;

/**
 * __useGetDatasetAssertionsQuery__
 *
 * To run a query within a React component, call `useGetDatasetAssertionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetAssertionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetAssertionsQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetDatasetAssertionsQuery(
    baseOptions: Apollo.QueryHookOptions<GetDatasetAssertionsQuery, GetDatasetAssertionsQueryVariables>,
) {
    return Apollo.useQuery<GetDatasetAssertionsQuery, GetDatasetAssertionsQueryVariables>(
        GetDatasetAssertionsDocument,
        baseOptions,
    );
}
export function useGetDatasetAssertionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetAssertionsQuery, GetDatasetAssertionsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDatasetAssertionsQuery, GetDatasetAssertionsQueryVariables>(
        GetDatasetAssertionsDocument,
        baseOptions,
    );
}
export type GetDatasetAssertionsQueryHookResult = ReturnType<typeof useGetDatasetAssertionsQuery>;
export type GetDatasetAssertionsLazyQueryHookResult = ReturnType<typeof useGetDatasetAssertionsLazyQuery>;
export type GetDatasetAssertionsQueryResult = Apollo.QueryResult<
    GetDatasetAssertionsQuery,
    GetDatasetAssertionsQueryVariables
>;
export const GetDatasetRunsDocument = gql`
    query getDatasetRuns($urn: String!, $start: Int!, $count: Int!, $direction: RelationshipDirection!) {
        dataset(urn: $urn) {
            runs(start: $start, count: $count, direction: $direction) {
                ...runResults
            }
        }
    }
    ${RunResultsFragmentDoc}
`;

/**
 * __useGetDatasetRunsQuery__
 *
 * To run a query within a React component, call `useGetDatasetRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetRunsQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *      start: // value for 'start'
 *      count: // value for 'count'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useGetDatasetRunsQuery(
    baseOptions: Apollo.QueryHookOptions<GetDatasetRunsQuery, GetDatasetRunsQueryVariables>,
) {
    return Apollo.useQuery<GetDatasetRunsQuery, GetDatasetRunsQueryVariables>(GetDatasetRunsDocument, baseOptions);
}
export function useGetDatasetRunsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetRunsQuery, GetDatasetRunsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDatasetRunsQuery, GetDatasetRunsQueryVariables>(GetDatasetRunsDocument, baseOptions);
}
export type GetDatasetRunsQueryHookResult = ReturnType<typeof useGetDatasetRunsQuery>;
export type GetDatasetRunsLazyQueryHookResult = ReturnType<typeof useGetDatasetRunsLazyQuery>;
export type GetDatasetRunsQueryResult = Apollo.QueryResult<GetDatasetRunsQuery, GetDatasetRunsQueryVariables>;
