/* eslint-disable */
import * as Types from '../types.generated';

import {
    OwnershipFieldsFragment,
    InstitutionalMemoryFieldsFragment,
    SchemaMetadataFieldsFragment,
    DeprecationFieldsFragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    OwnershipFieldsFragmentDoc,
    InstitutionalMemoryFieldsFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    DeprecationFieldsFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetGlossaryTermQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
    start?: Types.Maybe<Types.Scalars['Int']>;
    count?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type GetGlossaryTermQuery = { __typename?: 'Query' } & {
    glossaryTerm?: Types.Maybe<
        { __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'urn' | 'type' | 'name' | 'hierarchicalName'> & {
                isRelatedTerms?: Types.Maybe<
                    { __typename?: 'EntityRelationshipsResult' } & Pick<
                        Types.EntityRelationshipsResult,
                        'start' | 'count' | 'total'
                    > & {
                            relationships: Array<
                                { __typename?: 'EntityRelationship' } & {
                                    entity?: Types.Maybe<
                                        | { __typename?: 'Assertion' }
                                        | { __typename?: 'Chart' }
                                        | { __typename?: 'Container' }
                                        | { __typename?: 'CorpGroup' }
                                        | { __typename?: 'CorpUser' }
                                        | { __typename?: 'Dashboard' }
                                        | { __typename?: 'DataFlow' }
                                        | { __typename?: 'DataJob' }
                                        | { __typename?: 'DataPlatform' }
                                        | { __typename?: 'DataPlatformInstance' }
                                        | { __typename?: 'DataProcessInstance' }
                                        | { __typename?: 'Dataset' }
                                        | { __typename?: 'Domain' }
                                        | ({ __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'urn'>)
                                        | { __typename?: 'MLFeature' }
                                        | { __typename?: 'MLFeatureTable' }
                                        | { __typename?: 'MLModel' }
                                        | { __typename?: 'MLModelGroup' }
                                        | { __typename?: 'MLPrimaryKey' }
                                        | { __typename?: 'Notebook' }
                                        | { __typename?: 'Tag' }
                                        | { __typename?: 'VersionedDataset' }
                                    >;
                                }
                            >;
                        }
                >;
                hasRelatedTerms?: Types.Maybe<
                    { __typename?: 'EntityRelationshipsResult' } & Pick<
                        Types.EntityRelationshipsResult,
                        'start' | 'count' | 'total'
                    > & {
                            relationships: Array<
                                { __typename?: 'EntityRelationship' } & {
                                    entity?: Types.Maybe<
                                        | { __typename?: 'Assertion' }
                                        | { __typename?: 'Chart' }
                                        | { __typename?: 'Container' }
                                        | { __typename?: 'CorpGroup' }
                                        | { __typename?: 'CorpUser' }
                                        | { __typename?: 'Dashboard' }
                                        | { __typename?: 'DataFlow' }
                                        | { __typename?: 'DataJob' }
                                        | { __typename?: 'DataPlatform' }
                                        | { __typename?: 'DataPlatformInstance' }
                                        | { __typename?: 'DataProcessInstance' }
                                        | { __typename?: 'Dataset' }
                                        | { __typename?: 'Domain' }
                                        | ({ __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'urn'>)
                                        | { __typename?: 'MLFeature' }
                                        | { __typename?: 'MLFeatureTable' }
                                        | { __typename?: 'MLModel' }
                                        | { __typename?: 'MLModelGroup' }
                                        | { __typename?: 'MLPrimaryKey' }
                                        | { __typename?: 'Notebook' }
                                        | { __typename?: 'Tag' }
                                        | { __typename?: 'VersionedDataset' }
                                    >;
                                }
                            >;
                        }
                >;
                ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                institutionalMemory?: Types.Maybe<
                    { __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment
                >;
                properties?: Types.Maybe<
                    { __typename?: 'GlossaryTermProperties' } & Pick<
                        Types.GlossaryTermProperties,
                        'name' | 'description' | 'termSource' | 'sourceRef' | 'sourceUrl' | 'rawSchema'
                    > & {
                            customProperties?: Types.Maybe<
                                Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                            >;
                        }
                >;
                schemaMetadata?: Types.Maybe<{ __typename?: 'SchemaMetadata' } & SchemaMetadataFieldsFragment>;
                deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
            }
    >;
};

export const GetGlossaryTermDocument = gql`
    query getGlossaryTerm($urn: String!, $start: Int, $count: Int) {
        glossaryTerm(urn: $urn) {
            urn
            type
            name
            hierarchicalName
            isRelatedTerms: relationships(
                input: { types: ["IsA"], direction: OUTGOING, start: $start, count: $count }
            ) {
                start
                count
                total
                relationships {
                    entity {
                        ... on GlossaryTerm {
                            urn
                        }
                    }
                }
            }
            hasRelatedTerms: relationships(
                input: { types: ["HasA"], direction: OUTGOING, start: $start, count: $count }
            ) {
                start
                count
                total
                relationships {
                    entity {
                        ... on GlossaryTerm {
                            urn
                        }
                    }
                }
            }
            ownership {
                ...ownershipFields
            }
            institutionalMemory {
                ...institutionalMemoryFields
            }
            properties {
                name
                description
                termSource
                sourceRef
                sourceUrl
                rawSchema
                customProperties {
                    key
                    value
                }
            }
            schemaMetadata(version: 0) {
                ...schemaMetadataFields
            }
            deprecation {
                ...deprecationFields
            }
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
    ${SchemaMetadataFieldsFragmentDoc}
    ${DeprecationFieldsFragmentDoc}
`;

/**
 * __useGetGlossaryTermQuery__
 *
 * To run a query within a React component, call `useGetGlossaryTermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlossaryTermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlossaryTermQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *      start: // value for 'start'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetGlossaryTermQuery(
    baseOptions: Apollo.QueryHookOptions<GetGlossaryTermQuery, GetGlossaryTermQueryVariables>,
) {
    return Apollo.useQuery<GetGlossaryTermQuery, GetGlossaryTermQueryVariables>(GetGlossaryTermDocument, baseOptions);
}
export function useGetGlossaryTermLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetGlossaryTermQuery, GetGlossaryTermQueryVariables>,
) {
    return Apollo.useLazyQuery<GetGlossaryTermQuery, GetGlossaryTermQueryVariables>(
        GetGlossaryTermDocument,
        baseOptions,
    );
}
export type GetGlossaryTermQueryHookResult = ReturnType<typeof useGetGlossaryTermQuery>;
export type GetGlossaryTermLazyQueryHookResult = ReturnType<typeof useGetGlossaryTermLazyQuery>;
export type GetGlossaryTermQueryResult = Apollo.QueryResult<GetGlossaryTermQuery, GetGlossaryTermQueryVariables>;
